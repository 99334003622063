import overlays from '@utils/overlays';

const state = {
  overlays,
  keyboard: {
    input: null,
    caretPosition: null,
  },
  isLoading: true,
  isSaving: false,
  activeOverlay: {},
  nextActiveOverlay: {},
  activePinFlow: '',
  activeContentView: null,
  activeViewType: null,
  activeItemFilter: {},
  swipeDirection: '',
  swipeRef: '',
  dimmer: -1,
  currentDate: {},
};

const mutations = {
  SET_KEYBOARD_INPUT(state, input) {
    state.keyboard.input = input;
  },
  SET_KEYBOARD_CARET_POSITION(state, position) {
    state.keyboard.caretPosition = position;
  },
  SET_LOADING(state, loading) {
    state.isLoading = loading;
  },
  SET_SAVING(state, saving) {
    state.isSaving = saving;
  },
  SET_ACTIVE_OVERLAY(state, activeOverlay) {
    state.activeOverlay = activeOverlay;
  },
  SET_NEXT_ACTIVE_OVERLAY(state, nextActiveOverlay) {
    state.nextActiveOverlay = nextActiveOverlay;
  },
  RESET_OVERLAYS(state) {
    state.activeOverlay = {};
    state.nextActiveOverlay = {};
  },
  SET_ACTIVE_PINFLOW(state, flow) {
    state.activePinFlow = flow;
  },
  SET_ACTIVE_CONTENT_FILTER(state, activeContentView) {
    state.activeContentView = activeContentView;
  },
  SET_ACTIVE_VIEW_TYPE(state, activeViewType) {
    state.activeViewType = activeViewType;
  },
  SET_ACTIVE_ITEM_FILTER(state, activeItemFilter) {
    state.activeItemFilter = activeItemFilter;
  },
  SET_SWIPE_DIRECTION(state, swipeDirection) {
    state.swipeDirection = swipeDirection;
  },
  SET_SWIPE_REF(state, ref) {
    state.swipeRef = ref;
  },
  SET_DIMMER(state, dimmer) {
    state.dimmer = dimmer;
  },
  SET_CURRENT_DATE(state, date) {
    state.currentDate = date;
  },
};

const actions = {
  setKeyboardInput({ commit }, input) {
    commit('SET_KEYBOARD_INPUT', input);
  },
  setKeyboardCaretPosition({ commit }, caretPosition) {
    commit('SET_KEYBOARD_CARET_POSITION', caretPosition);
  },
  closeKeyboard({ commit }) {
    commit('SET_KEYBOARD_INPUT', null);
    commit('SET_KEYBOARD_CARET_POSITION', null);
  },
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading);
  },
  setSaving({ commit }, saving) {
    commit('SET_SAVING', saving);
  },
  setActiveOverlay({ commit }, activeOverlay) {
    const overlay = state.overlays.find(
      (overlay) => overlay.name === activeOverlay.name
    );
    if (activeOverlay.data) overlay.data = activeOverlay.data;
    commit('SET_ACTIVE_OVERLAY', overlay || {});

    if (activeOverlay.name) {
      window._paq.push(['trackEvent', 'OverlayViewed', activeOverlay.name]);
    }
  },
  setNextActiveOverlay({ commit }, nextActiveOverlay) {
    commit('SET_NEXT_ACTIVE_OVERLAY', nextActiveOverlay);
  },
  resetOverlays({ commit }) {
    commit('RESET_OVERLAYS');
  },
  setActivePinFlow({ commit }, flow) {
    commit('SET_ACTIVE_PINFLOW', flow);
  },
  setActiveContentView({ commit }, activeContentView) {
    commit('SET_ACTIVE_CONTENT_FILTER', activeContentView);
  },
  setActiveViewType({ commit }, activeViewType) {
    commit('SET_ACTIVE_VIEW_TYPE', activeViewType);
    window._paq.push(['trackEvent', 'ViewType', activeViewType]);
  },
  setActiveItemFilter({ commit }, activeItemFilter) {
    commit('SET_ACTIVE_ITEM_FILTER', activeItemFilter);
  },
  setSwipeDirection({ commit }, swipeDirection) {
    commit('SET_SWIPE_DIRECTION', swipeDirection);
  },
  setSwipeRef({ commit }, ref) {
    commit('SET_SWIPE_REF', ref);
  },
  setDimmer({ commit }, dimmer) {
    if (!dimmer) {
      // If localStorage value is empty, parseInt returns -1
      dimmer = parseInt(localStorage.getItem('dimmer')) || 100;
    }

    localStorage.setItem('dimmer', dimmer);
    commit('SET_DIMMER', dimmer);
  },
  setCurrentDate({ commit }, date) {
    commit('SET_CURRENT_DATE', date);
  },
};

const getters = {
  keyboardInput: (state) => state.keyboard.input,
  keyboardCaretPosition: (state) => state.keyboard.caretPosition,
  isLoading: (state) => state.isLoading,
  isSaving: (state) => state.isSaving,
  activeOverlay: (state) => state.activeOverlay,
  nextActiveOverlay: (state) => state.nextActiveOverlay,
  activePinFlow: (state) => state.activePinFlow,
  activeContentView: (state) => state.activeContentView,
  activeViewType: (state) => state.activeViewType,
  activeItemFilter: (state) => state.activeItemFilter,
  overlays: (state) => state.overlays,
  swipeDirection: (state) => state.swipeDirection,
  swipeRef: (state) => state.swipeRef,
  dimmer: (state) => state.dimmer,
  currentDate: (state) => state.currentDate,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
