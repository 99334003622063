<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { default as ButtonEl } from '@components/shared/Button.vue';
import PinFlow from '@utils/pinFlow';

const store = useStore();

const emit = defineEmits(['close-overlay']);

const props = defineProps({
  shift: {
    type: Object,
    default: {},
  },
});

const isEditing = ref(false);
const confirmation = ref(false);
const confirmButtonText = ref('');
const action = ref('');

const departments = computed(() => store.getters['institution/departments']);

function cancel() {
  confirmButtonText.value = 'scheduleShift.confirmCancelationButton';
  action.value = 'cancel';
  confirmation.value = true;
}

function reactivate() {
  confirmButtonText.value = 'scheduleShift.confirmReactivationButton';
  action.value = 'reactivate';
  confirmation.value = true;
}

function remove() {
  confirmButtonText.value = 'scheduleShift.confirmDeleteButton';
  action.value = 'delete';
  confirmation.value = true;
}

function replace() {
  action.value = 'replace';
  store.dispatch('general/setNextActiveOverlay', {
    name: 'replace-shift-employee',
    data: { selectedShift: props.shift, action: action.value },
  });

  editShift();
}

function editShift() {
  store.dispatch('workSchedule/setEditAction', action.value);
  emit('close-overlay');

const timeout =  setTimeout(() => {
  clearTimeout(timeout)
    const flow = new PinFlow({
      entity: 'shift',
      action: action.value,
      employeeName: props.shift.employee.displayName,
      sharedDepartments: departments.value,
    });

    flow.startUpdateShiftFlow('shift');
  }, 700);
}
</script>

<template>
  <div class="flex justify-evenly w-full mt-96">
    <button-el
      v-if="!isEditing"
      text="scheduleShift.editShiftButton"
      icon="edit"
      :shadow="true"
      class="self-center"
      background-color="white"
      @click="isEditing = !isEditing"
    />

    <div v-if="isEditing && !confirmation" class="flex justify-evenly w-full">
      <button-el
        v-if="!shift.cancelled"
        text="scheduleShift.cancelButton"
        icon="ban"
        :shadow="true"
        class="self-center"
        background-color="warning"
        @click="cancel()"
      />
      <button-el
        v-if="shift.cancelled"
        text="scheduleShift.reactivateButton"
        icon="fa-exchange-alt"
        :shadow="true"
        class="self-center"
        background-color="warning"
        @click="reactivate()"
      />
      <button-el
        text="scheduleShift.replace"
        icon="fa-exchange-alt"
        :shadow="true"
        class="self-center"
        background-color="success"
        @click="replace()"
      />
      <button-el
        text="scheduleShift.delete"
        icon="trash"
        :shadow="true"
        class="self-center"
        background-color="error"
        @click="remove()"
      />
    </div>
    <div v-if="confirmation" class="flex justify-evenly w-full">
      <button-el
        :text="confirmButtonText"
        icon="ban"
        :shadow="true"
        class="self-center"
        :background-color="action === 'cancel' ? 'warning' : 'error'"
        @click="editShift()"
      />
      <button-el
        text="global.cancel"
        icon="undo"
        :shadow="true"
        class="self-center"
        background-color="white"
        @click="confirmation = !confirmation"
      />
    </div>
  </div>
</template>
