<script setup>
const props = defineProps({
  icon: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
});
</script>

<template>
  <div class="relative content flex items-center w-full">
    <fa-icon
      class="mr-12 text-3xl self-start"
      :icon="['far', `${props.icon}`]"
      :aria-label="$t('global.date')"
    />

    <div class="flex flex-col">
      <div>
        <p class="b500">
          {{ props.text }}
        </p>
      </div>
    </div>
  </div>
</template>
