<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { getBookingById } from '@utils/bookings.js';
import ProfilesList from '@components/shared/ProfilesList.vue';
import { useStore } from 'vuex';
import { get } from 'lodash';

const store = useStore();
const router = useRouter();

const emit = defineEmits(['close-overlay']);

const booking = computed(
  () => store.getters['bookingResources/selectedBookingResource']
);

const departments = computed(() => {
  return get(booking.value, 'departments', []);
});

async function save(participants) {
  await store.dispatch('general/setSaving', true);
  await store.dispatch('bookingResources/setNewBooking', {
    participants,
  });

  await store
    .dispatch('bookingResources/createNewBooking')
    .then(async (id) => {
      await store.dispatch('bookingResources/getBookingResources');
      await store.dispatch('general/setSaving', false);
      await router.push('/bookings');
      const booking = getBookingById(id);

      await store.dispatch('bookingResources/setSelectedBookingEntry', booking);
      emit('close-overlay');

      setTimeout(() => {
        router.push(`/bookings/booking/${id}`);
      }, 1000);
    })
    .catch(() => {
      store.dispatch('general/setNextActiveOverlay', {
        name: 'error',
        data: {
          title: 'global.error',
          descriptionOne: 'booking.doubleBooking',
          descriptionTwo: 'booking.chooseDifferentTime',
          buttonText: 'global.tryAgain',
        },
      });
      emit('close-overlay');
    });
}
</script>

<template>
  <div>
    <div class="content w-full mb-12 mx-20">
      {{ $t('booking.selecttParticipantsDescription') }}
    </div>

    <profiles-list
      :can-save="true"
      :multiselect="true"
      :shared-departments="departments"
      select-bottom="bottom-48"
      @save="save"
    />
  </div>
</template>
