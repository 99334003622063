<script setup>
import { format } from '@utils/date-fns';
import { computed, ref, onMounted, watch } from 'vue';
import { toUnix } from '@utils/helpers.js';

const props = defineProps({
  image: {
    type: String,
    default: '/img/user-placeholder.png',
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  start: {
    type: Date,
    required: true,
  },
  end: {
    type: Date,
    required: true,
  },
  imageWidth: {
    type: Number,
    default: 200,
  },
  showProgressBar: {
    type: Boolean,
    default: true,
  },
  showTime: {
    type: Boolean,
    default: false,
  },
  hasUnavailability: {
    type: Boolean,
    default: false,
  },
  isCancelled: {
    type: Boolean,
    default: false,
  },
  isNightShift: {
    type: Boolean,
    default: false,
  },
});

const shiftProgress = computed(() => {
  const start = toUnix(props.start);
  const end = toUnix(props.end);
  const now = Math.floor(Date.now());

  const percentageCompleted = ((now - start) / (end - start)) * 100;
  return Math.floor(percentageCompleted);
});

const progress = ref(0);

const strokeWidth = computed(() => {
  return props.imageWidth >= 130 ? 10 : 7;
});

const circleSize = computed(() => {
  return props.imageWidth;
});

const circumference = computed(
  () => 2 * Math.PI * (circleSize.value / 2 - strokeWidth.value)
);

const progressStyle = computed(() => {
  const offset =
    circumference.value - (progress.value / 100) * circumference.value;
  return {
    strokeDasharray: circumference.value,
    strokeDashoffset: offset,
    strokeWidth: strokeWidth.value,
  };
});

const imageSize = computed(() => {
  return props.imageWidth - strokeWidth.value * 2;
});

onMounted(() => {
  if (!props.showProgressBar) return;

  setTimeout(() => {
    progress.value = shiftProgress.value;
  }, 1200);
});
</script>

<template>
  <div
    class="flex flex-col items-center justify-center w-full place-self-center h-fit"
  >
    <div
      v-if="props.showProgressBar && !props.isCancelled"
      :style="{ width: circleSize + 'px', height: circleSize + 'px' }"
      class="progress-circle"
    >
      <svg :width="circleSize" :height="circleSize">
        <circle
          class="progress-background"
          :style="{ 'stroke-width': `${strokeWidth}px` }"
          :cx="circleSize / 2"
          :cy="circleSize / 2"
          :r="circleSize / 2 - 10"
        ></circle>

        <circle
          class="progress-bar"
          :style="progressStyle"
          :cx="circleSize / 2"
          :cy="circleSize / 2"
          :r="circleSize / 2 - 10"
        ></circle>
      </svg>

      <img
        class="circle-image"
        :style="{ width: imageSize + 'px', height: imageSize + 'px' }"
        :src="image"
      />
    </div>

    <div
      v-else
      class="flex items-center justify-center"
      :style="{ width: circleSize + 'px', height: circleSize + 'px' }"
    >
      <img
        :src="image"
        :class="{ grayscale: isCancelled }"
        class="aspect-square h-full object-cover rounded-full"
        :style="{
          width: imageSize - strokeWidth + 'px',
          height: imageSize - strokeWidth + 'px',
        }"
      />
    </div>

    <div
      class="flex flex-col items-center justify-center text-center w-full max-w-[200px]"
    >
      <div class="flex w-full px-2 items-center justify-center">
        <p class="h600 mr-2 truncate">{{ name }}</p>

        <div
          v-if="hasUnavailability && !isCancelled"
          class="rounded-full min-w-6 w-6 h-6 bg-red-400 border-2 border-white"
        ></div>

        <div
          v-else-if="isCancelled"
          class="rounded-full min-w-6 w-6 h-6 bg-warning border-2 border-white flex items-center justify-center"
        >
          <fa-icon class="text-white" :icon="['far', 'times']" />
        </div>

        <div
          v-else-if="isNightShift"
          class="rounded-full w-6 h-6 min-w-6 bg-charcoal border-2 border-white flex items-center justify-center"
        >
          <fa-icon class="text-white text-xs" :icon="['fas', 'moon-stars']" />
        </div>
      </div>

      <div
        class="flex items-center justify-center"
        v-if="showTime && isCancelled"
      >
        <p class="w-fit py-1 px-2 h50 bg-yellow-300 rounded-full">
          {{ $t('scheduleShift.notComing') }}
        </p>
      </div>

      <div v-else-if="showTime">
        <p class="h200">
          {{ format(start, 'HH:mm') }} - {{ format(end, 'HH:mm') }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.progress-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-circle svg {
  position: absolute;
  transform: rotate(-90deg);
}

.progress-circle circle {
  fill: none;
  stroke-linecap: round;
}

.progress-background {
  stroke: #bbd3d4;
}

.progress-bar {
  stroke: #186571;
  transition: stroke-dashoffset 1s ease;
}

.circle-image {
  border-radius: 50%;
  object-fit: cover;
}
</style>
