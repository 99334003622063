<script setup>
import { format } from '@utils/date-fns';
import { capitalize } from 'lodash';
import { computed } from 'vue';

const props = defineProps({
  start: {
    type: String,
    default: '',
  },
  end: {
    type: String,
    default: '',
  },
  useTextInsteadOfTime: {
    type: Boolean,
    default: false,
  },
});

const formattedStartTime = computed(() => {
  return format(new Date(props.start), 'HH:mm');
});

const formattedEndTime = computed(() => {
  return format(new Date(props.end), 'HH:mm');
});
</script>

<template>
  <div class="relative content flex items-center w-full">
    <fa-icon
      class="mr-12 text-3xl self-start"
      :icon="['fal', 'clock']"
      :aria-label="$t('global.date')"
    />

    <div class="flex flex-col">
      <div>
        <p class="h700">
          {{ $t('global.oclock') }}
        </p>

        <span
          class="b500"
          :aria-label="`${formattedStartTime} - ${formattedEndTime}`"
        >
          {{ formattedStartTime }} - {{ formattedEndTime }}</span
        >
      </div>
    </div>
  </div>
</template>
