<script setup>
import {
  isAfter,
  differenceInMinutes,
  isSameDay,
  setHours,
  getDate,
  getMonth,
  addDays,
} from 'date-fns';
import { ref, computed, onMounted } from 'vue';
import { toDate } from 'date-fns-tz';
import { clone } from 'lodash';
import constants from '@utils/constants';
import { format } from '@utils/date-fns';
import { useStore } from 'vuex';

const store = useStore();
const lastScrollLeft = ref(60);
const shiftDetailsLeft = ref(40);
const imageLeft = ref(60);
const showName = ref(true);
const showTime = ref(true);
const today = ref(new Date());

const props = defineProps({
  shift: {
    type: Object,
    default: () => {},
  },
  moduleColor: {
    type: String,
    default: '',
  },
  index: {
    type: Number,
    default: -1,
  },
  viewWidth: {
    type: String,
    default: '',
  },
  selectedDay: {
    type: Date,
    default: () => {},
  },
});

const settings = computed(() => {
  return store.getters['institution/settings'];
});

const now = computed(() => {
  const shiftStart = new Date(props.shift.start);
  return isSameDay(today.value, shiftStart)
    ? shiftStart
    : setHours(new Date(shiftStart), 0);
});

const shiftStart = computed(() => {
  return toDate(props.shift.start, { timeZone: constants.timezone });
});

const shiftEnd = computed(() => {
  return toDate(props.shift.end, { timeZone: constants.timezone });
});

const startTime = computed(() => {
  return format(new Date(props.shift.start), 'HH:mm');
});

const endTime = computed(() => {
  return format(new Date(props.shift.end), 'HH:mm');
});

const unavailabilityStart = computed(() => {
  return toDate(props.shift.unavailable[0].start, {
    timeZone: constants.timezone,
  });
});

const unavailabilityEnd = computed(() => {
  return toDate(props.shift.unavailable[0].end, {
    timeZone: constants.timezone,
  });
});

const unavailableNow = computed(() => {
  if (!props.shift.unavailable) return false;

  if (
    isAfter(today.value, unavailabilityStart.value) &&
    isAfter(unavailabilityEnd.value, today.value)
  )
    return true;
  return false;
});

const backgroundColor = computed(() => {
  if (shiftCancelled.value) return 'bg-warning';
  if (shiftOver.value || !shiftStarted.value) return 'bg-charcoal-xlight';

  return `bg-${props.moduleColor}`;
});

const textColor = computed(() => {
  if (shiftOver.value || shiftCancelled.value || !shiftStarted.value)
    return 'text-black';
  return 'text-white';
});

const shiftStarted = computed(() => {
  return (
    isAfter(today.value, shiftStart.value) &&
    isAfter(shiftEnd.value, today.value)
  );
});

const shiftOver = computed(() => {
  return isAfter(today.value, shiftEnd.value);
});

const shiftCancelled = computed(() => {
  return props.shift.cancelled;
});

const hasUnavailability = computed(() => {
  return !!props.shift.unavailable;
});

const oneMinuteWidth = computed(() => {
  return window.innerWidth / 12 / 60;
});

const shiftInMinutes = computed(() => {
  return differenceInMinutes(shiftEnd.value, shiftStart.value);
});

const isStartDateBeforeSelectedDate = computed(() => {
  const selectedDay = getDate(props.selectedDay);
  const selectedMonth = getMonth(props.selectedDay) + 1;
  const shiftDay = getDate(new Date(props.shift.start));
  const shiftMonth = getMonth(new Date(props.shift.start)) + 1;

  return shiftDay < selectedDay || shiftMonth < selectedMonth;
});

const toMidnight = computed(() => {
  return oneMinuteWidth.value * 6 * 60;
});

const shiftWidth = computed(() => {
  // Recalculate if we should show time or name when width recomputes.
  showTime.value = true;
  showName.value = true;

  const today = clone(now.value);
  const midnightToday = today.setHours(0, 0, 0, 0);
  const minutesSinceNextMidnightWidth = differenceInMinutes(
    shiftEnd.value,
    addDays(midnightToday, 1)
  );

  if (!isStartDateBeforeSelectedDate.value) {
    return shiftInMinutes.value * oneMinuteWidth.value;
  }

  return (
    toMidnight.value + minutesSinceNextMidnightWidth * oneMinuteWidth.value + 25
  );
});

const shiftMarginLeft = computed(() => {
  // Increase left margin the longer from midnight the shift starts.
  const today = clone(now.value);
  const midnightToday = today.setHours(0, 0, 0, 0);

  if (!isStartDateBeforeSelectedDate.value) {
    // If shift starts before the current chosen day, don't add left margin.
    const minutesSinceMidnightWidth =
      differenceInMinutes(shiftStart.value, midnightToday) *
        oneMinuteWidth.value +
      25;
    return `${toMidnight.value + minutesSinceMidnightWidth}px`;
  }

  return '0px';
});

const unavailabilityWidth = computed(() => {
  const minutes = differenceInMinutes(
    unavailabilityEnd.value,
    unavailabilityStart.value
  );
  return minutes * oneMinuteWidth.value;
});

const unavailabilityMarginLeft = computed(() => {
  const minutesFromStart = differenceInMinutes(
    unavailabilityStart.value,
    shiftStart.value
  );
  return minutesFromStart * oneMinuteWidth.value;
});

function getProfileImage(employee) {
  const placeholder = '/img/user-placeholder.png';

  if (!employee.showImage || !employee.displayImage.length) return placeholder;

  return settings.value.vismaGatWs
    ? `data:image/jpg;base64,${employee.displayImage}`
    : employee.displayImage;
}

onMounted(() => {
  setTimeout(() => {
    lastScrollLeft.value = document.querySelector('.schedule').scrollLeft;
    imageLeft.value = lastScrollLeft.value + 40;
    const shift = document.querySelectorAll('.shift')[props.index];
    const name = document.querySelectorAll('.name')[props.index];
    const time = document.querySelectorAll('.time')[props.index];
    const image = document.querySelectorAll('.image')[props.index];

    document.querySelector('.schedule').addEventListener('scroll', (event) => {
      lastScrollLeft.value = event.target.scrollLeft;
      imageLeft.value = lastScrollLeft.value + 40;
      const imageBCR = image.getBoundingClientRect();
      const shiftBCR = shift.getBoundingClientRect();
      const nameBCR = name.getBoundingClientRect();
      const timeBCR = time.getBoundingClientRect();

      if (imageBCR.right >= shiftBCR.left && imageBCR.right <= shiftBCR.right) {
        shiftDetailsLeft.value = imageBCR.right - shiftBCR.x + 40;
        showTime.value = timeBCR.right + 20 < shiftBCR.right;
      } else if (imageBCR.right <= shiftBCR.left) {
        shiftDetailsLeft.value = 40;
      }

      showName.value = nameBCR.right + 20 < shiftBCR.right;
      showTime.value = shiftBCR.width > 200 ? showTime.value : false;
    });
  }, 0);
});
</script>

<template>
  <div
    class="h-36 w-full mb-8 flex flex-row relative"
    :style="{ width: viewWidth }"
    :class="{ 'mt-12': index === 0 }"
  >
    <div
      class="image absolute z-20 bg-white rounded-full"
      :style="{ marginLeft: `${imageLeft}px` }"
    >
      <img
        :src="getProfileImage(shift.employee)"
        alt="Employee"
        class="w-36 h-36 rounded-full border-8 border-white shadow-bottom z-20 object-cover"
        :class="[{ 'filter grayscale': shiftOver || shiftCancelled }]"
      />

      <div
        v-if="shift.nightShift"
        class="bg-charcoal z-10 absolute w-12 h-12 rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom"
      >
        <fa-icon class="text-white text-xl" :icon="['fas', 'moon-stars']" />
      </div>

      <div
        v-else-if="shift.cancelled"
        class="bg-warning z-10 absolute w-12 h-12 rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom"
      >
        <fa-icon class="text-white text-2xl" :icon="['far', 'times']" />
      </div>

      <div
        v-else-if="unavailableNow"
        class="z-10 absolute w-12 h-12 rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom bg-orange"
      />

      <div
        v-else-if="shiftStarted"
        class="z-10 absolute w-12 h-12 rounded-full flex items-center justify-center -mt-12 ml-28 border-4 border-white shadow-bottom"
        :class="`bg-${moduleColor}`"
      />
    </div>

    <div
      class="shift h-full z-10 rounded-lg shadow-bottom flex items-center"
      :class="backgroundColor"
      :style="{ width: `${shiftWidth}px`, 'margin-left': shiftMarginLeft }"
    >
      <div
        class="shiftData absolute flex flex-col justify-center z-10"
        :style="{ marginLeft: `${shiftDetailsLeft}px` }"
      >
        <p
          class="w-max text-3xl font-semibold name"
          :class="[
            textColor,
            { 'opacity-0': !showName, 'opacity-1': showName },
          ]"
        >
          {{ shift.employee.displayName }}
        </p>

        <p
          class="w-max text-3xl font-light time"
          :class="[
            textColor,
            { 'opacity-0': !showTime, 'opacity-1': showTime },
          ]"
        >
          {{ startTime }} - {{ endTime }}
        </p>
      </div>
      <div
        v-if="hasUnavailability && !shiftOver && !shiftCancelled"
        class="h-full bg-orange absolute"
        :class="{
          'rounded-l-lg': unavailabilityMarginLeft === 0,
          'rounded-r-lg':
            unavailabilityWidth + unavailabilityMarginLeft === shiftWidth,
        }"
        :style="{
          'margin-left': `${unavailabilityMarginLeft}px`,
          width: `${unavailabilityWidth}px`,
        }"
      />
    </div>
  </div>
</template>
