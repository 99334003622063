<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import RadioListItem from '@components/shared/RadioListItem.vue';

const store = useStore();

const showOptions = ref(false);

const departments = computed(() => {
  return store.getters['institution/departmentSwitcherDepartments'];
});

const currentDepartment = computed(() => {
  return store.getters['department/current'];
});

async function switchDepartment(department) {
  await store.dispatch('department/switchDepartment', department);
};

function onClickoutside() {
  if (showOptions.value) {
    showOptions.value = false;
  }
}
</script>

<template>
  <div class="departmentSwitcherContainer flex justify-center pb-8">
    <!-- Backdrop shown when selector is open-->
    <div
      v-if="showOptions && currentDepartment.name"
      class="w-full h-screen absolute backdrop bottom-0"
    />

    <div
      class="relative"
      v-clickoutside="{
        excludeString: '.options, .options>*, .selection, .selection>*',
        handler: onClickoutside,
      }"
    >
      <!-- Departments list selector -->
      <div
        v-if="showOptions"
        class="z-75 -translate-x-36 absolute bottom-20 bg-white rounded-2xl border border-neutral300 px-6 pt-6 pb-6"
      >
        <p class="h700 text-center mb-4">
          {{ $t('global.selectDepartment') }}
        </p>

        <div
          v-if="currentDepartment.name"
          class="options"
          :class="{ 'pr-4 overflow-y-scroll': departments.length > 4 }"
        >
          <radio-list-item
            v-for="(department, i) in departments"
            :key="i"
            :option="department"
            :is-selected="department.id === currentDepartment.id"
            @option-selected="switchDepartment(department)"
          />
        </div>
      </div>

      <!-- Icon, name and chevron -->
      <div
        class="flex selection items-center justify-between z-75 bg-white rounded-full px-6"
        @click="showOptions = !showOptions"
      >
        <div
          class="flex items-center justify-center rounded-full"
          :style="{
            backgroundColor: currentDepartment.color,
            height: '40px',
            width: '40px',
          }"
        >
          <fa-icon class="text-xl text-white" :icon="['fas', 'home']" />
        </div>

        <p class="h400">
          {{ currentDepartment.name }}
        </p>

        <div class="items-center">
          <fa-icon
            class="text-lg"
            :icon="['far', showOptions ? 'chevron-up' : 'chevron-down']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.departmentSwitcherContainer {
  ::-webkit-scrollbar {
    display: block;
  }

  .backdrop {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  } 

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #d1d1d1;
  }

  .selection {
    width: 445px;
    height: 65px;
  }

  .options {
    width: 680px;
    max-height: 516px;

    .option {
      height: 96px;
    }
  }
}
</style>
