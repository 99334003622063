<template>
  <div
    class="bottom-[310px] bg-white h-48 flex p-12 flex-row w-full overflow-none z-20"
  >
    <div
      class="w-24 h-24 rounded-lg flex items-center justify-center"
      :class="canGoBack ? activeClasses : inactiveClasses"
      @click="goBack()"
    >
      <fa-icon class="text-charcoal text-5xl" :icon="['fal', 'angle-left']" />
    </div>

    <div
      class="h-24 flex-1 mx-12 rounded-lg flex items-center justify-center"
      :class="inactiveClasses"
    >
      <p class="text-3xl font-bold text-black">
        {{ formattedSelectedDate }}
      </p>
    </div>

    <div
      class="w-24 h-24 rounded-lg flex items-center justify-center"
      :class="canGoForward ? activeClasses : inactiveClasses"
      @click="goForward()"
    >
      <fa-icon class="text-charcoal text-5xl" :icon="['fal', 'angle-right']" />
    </div>
  </div>
</template>

<script>
import { get, capitalize } from 'lodash';
import { differenceInDays, isSameDay, addDays, subDays } from 'date-fns';
import { format } from '@utils/date-fns';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedDay: new Date(),
      inactiveClasses:
        'bg-schedule-light border border-schedule-dark-default opacity-50',
      activeClasses: 'bg-schedule-light border border-schedule-dark-default',
    };
  },
  computed: {
    ...mapGetters({
      departmentSettings: 'department/settings',
      institutionSettings: 'institution/settings',
      departmentId: 'department/id',
    }),
    formattedSelectedDate() {
      if (isSameDay(this.selectedDay, this.today))
        return this.$t('global.today');
      return capitalize(format(this.selectedDay, 'EEEE do LLLL'));
    },
    shownDays() {
      // If the department has checked overwrite institution setting it will be in the shift array.
      const matchingDepartment = this.institutionSettings?.shift?.find(
        ({ departmentId }) => departmentId == this.departmentId
      );

      return (
        get(
          matchingDepartment,
          'daysForward',
          this.departmentSettings.shift.daysForward
        ) - 1
      ); // Subtract 1 because the core admin setting is not 0 based but 1 should mean 1 days forward.
    },
    today() {
      return new Date();
    },
    canGoBack() {
      const differenceFromToday = differenceInDays(
        this.today,
        this.selectedDay
      );
      return differenceFromToday < this.shownDays;
    },
    canGoForward() {
      const differenceFromToday = differenceInDays(
        this.selectedDay,
        this.today
      );
      return differenceFromToday < this.shownDays;
    },
  },
  methods: {
    goBack() {
      if (!this.canGoBack) return;
      this.selectedDay = subDays(this.selectedDay, 1);
      this.$emit('handle-selected-day', this.selectedDay);
    },
    goForward() {
      if (!this.canGoForward) return;
      this.selectedDay = addDays(this.selectedDay, 1);
      this.$emit('handle-selected-day', this.selectedDay);
    },
  },
};
</script>
