<script setup>
import 'vueperslides/dist/vueperslides.css';
import { getModuleData } from '@utils/modules';
import { format } from '@utils/date-fns';
import { parseISO } from 'date-fns';
import { getStartDay } from '@utils/helpers.js';
import { capitalize } from 'lodash';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import CustomCarousel from '../shared/CustomCarousel.vue';
import i18n from '@i18n';
import { VueperSlide } from 'vueperslides';
import constants from '@utils/constants.js';
import BaseContentCard from '@components/cards/baseContentCard/BaseContentCard.vue';
import WidgetEmptyCard from '@components/cards/widgetEmptyCard/WidgetEmptyCard.vue';

const activeDay = ref(1);
const store = useStore();
const slideIndex = ref(0);
const sliderReady = ref(false);

const props = defineProps({
  widget: {
    type: Object,
    default: () => {},
  },
});

const { colorHex, icon, emptyKey, routeName } = getModuleData().WEATHER;

onMounted(() => {
  store.dispatch('department/getWeather');
});

const weather = computed(() => {
  return store.getters['department/weather'];
});

const prognosis = computed(() => {
  if (Object.keys(weather?.value)?.length < 1) {
    return [];
  }

  // Ensure a Date object in UTC format, due to timezone differences between browser and BE.
  const nowInUTC = new Date(new Date().toUTCString());

  const todayDate = format(new Date(), 'yyyy-MM-dd');

  // Find the closest forecast to the current time.
  const closestForecast = weather.value?.properties?.timeseries.reduce(
    (closest, current) => {
      const currentTime = parseISO(current.time); // convert ISO dateString into Date object.
      const closestTime = parseISO(closest.time);

      const timeDiffToCurrent = Math.abs(
        nowInUTC.getTime() - currentTime.getTime()
      );

      const timeDiffToClosest = Math.abs(
        nowInUTC.getTime() - closestTime.getTime()
      );

      const isCurrentCloser = timeDiffToCurrent <= timeDiffToClosest;

      // Return the one with least difference, as it's closest to the current time.
      return isCurrentCloser ? current : closest;
    }
  );

  // Find the series for tomorrow 12:00 since that is most consistent.
  const tomorrowNoon = weather.value.properties.timeseries.find((series) => {
    const seriesDate = format(parseISO(series.time), 'yyyy-MM-dd');
    const seriesTime = format(parseISO(series.time), 'HH:mm');

    return seriesDate > todayDate && seriesTime === '12:00'; // -> 2024-09-04 > 2024-09-03 AND time is 12:00.
  });

  // Widget only shows today and tommorrow.
  const dates = [closestForecast, tomorrowNoon];

  return dates.map((day, index) => {
    const isToday = index === 0;
    const instant = day.data.instant.details;
    const summary = isToday
      ? day.data.next_1_hours.summary.symbol_code.split('_')[0]
      : day.data.next_12_hours.summary?.symbol_code.split('_')[0];

    const wind = Math.round(instant.wind_speed);
    const weatherData = getWeatherData(summary, wind);

    return {
      temp: Math.round(instant.air_temperature),
      wind,
      coverImage: weatherData.image,
      weekday: getStartingDay(day.time),
      icon: weatherData.icon,
      summary,
    };
  });
});

const bulletTitles = computed(() => {
  if (Object.keys(weather.value).length < 1) {
    return;
  }
  return prognosis?.value?.map((day) => day.weekday);
});

function getStartingDay(dateString) {
  const startDay = getStartDay(dateString, true);
  return startDay.includes('.')
    ? capitalize(i18n.global.t(startDay))
    : capitalize(startDay);
}

function selectDay(index) {
  const selectedIndex = index || slideIndex.value;
  activeDay.value = selectedIndex + 1;
}

function getWeatherData(summary, wind) {
  switch (summary) {
    case 'fair':
    case 'clearsky':
      return {
        image: 'clear_day',
        icon: 'sun',
      };
    case 'cloudy':
      return wind > 10
        ? {
            image: 'wind',
            icon: 'wind',
          }
        : {
            image: 'wind',
            icon: 'clouds',
          };
    case 'partlycloudy':
      return wind > 10
        ? {
            image: 'wind',
            icon: 'wind',
          }
        : {
            image: 'partly-cloudy-day',
            icon: 'cloud-sun',
          };
    case 'heavyrain':
    case 'heavyrainshowers':
    case 'lightrain':
    case 'lightrainshowers':
    case 'rain':
    case 'rainshowers':
      return {
        image: 'rain',
        icon: 'cloud-rain',
      };
    case 'heavyrainandthunder':
    case 'heavyrainshowersandthunder':
    case 'lightrainandthunder':
    case 'lightrainshowersandthunder':
    case 'rainandthunder':
    case 'rainshowersandthunder':
    case 'heavysleetandthunder':
    case 'heavysleetshowersandthunder':
    case 'lightsleetandthunder':
    case 'lightssleetshowersandthunder':
    case 'sleetandthunder':
    case 'sleetshowersandthunder':
      return {
        image: 'thunderstorm',
        icon: 'cloud-bolt',
      };
    case 'heavysleet':
    case 'heavysleetshowers':
    case 'lightsleet':
    case 'lightsleetshowers':
    case 'sleet':
    case 'sleetshowers':
      return {
        image: 'hail',
        icon: 'cloud-hail-mixed',
      };
    case 'heavysnow':
    case 'heavysnowandthunder':
    case 'heavysnowshowers':
    case 'heavysnowshowersandthunder':
    case 'lightsnow':
    case 'lightsnowandthunder':
    case 'lightsnowshowers':
    case 'lightssnowshowersandthunder':
    case 'snow':
    case 'snowandthunder':
    case 'snowshowers':
    case 'snowshowersandthunder':
      return {
        image: 'snow',
        icon: 'snowflake',
      };
    case 'fog':
      return {
        image: 'fog',
        icon: 'cloud-fog',
      };
    default:
      return undefined;
  }
}

function getBackgroundImageStyle(item) {
  return `url(/img/weather/${item.coverImage}.jpg)`;
}

function onSlide(index) {
  slideIndex.value = index;
  selectDay();
}

function onSliderReady() {
  sliderReady.value = true;
  slideIndex.value = 0;
}
</script>

<template>
  <BaseContentCard
    v-if="weather && prognosis.length"
    class="bg-macaron-100"
    :routeName="routeName"
    :backgroundColor="colorHex"
    :emptyKey="emptyKey"
    :shouldShowDate="false"
    :useBackgroundImage="false"
    :showFooter="false"
  >
    <template v-slot:main>
      <CustomCarousel
        :slide-count="prognosis.length"
        :autoplay="false"
        :custom-bullets="true"
        :custom-bullet-titles="bulletTitles"
        @slide="onSlide($event)"
        @ready="onSliderReady()"
      >
        <VueperSlide
          v-for="(item, index) in prognosis"
          :key="index"
          :class="[
            constants.slider.slideStyles,
            props?.widget?.frHeight > 1 ? 'justify-center' : 'justify-end',
          ]"
          :style="{ background: getBackgroundImageStyle(item) }"
        >
          <template #content>
            <div class="w-full h-full flex justify-between p-6">
              <p class="text-7xl textShadow">{{ item.temp }}&deg;</p>
              <p class="text-2xl mt-4">
                <fa-icon
                  class="text-white text-xl mr-2 textShadow"
                  :icon="['fas', 'location-arrow']"
                />
                <span class="textShadow">{{ item.wind }} m/s</span>
              </p>
            </div>

            <div class="mb-12 flex flex-col items-center">
              <fa-icon
                class="text-4xl mb-4 textShadow"
                :icon="['fas', `${item.icon}`]"
              />
              <p class="text-4xl textShadow">
                {{ $t('weather.moduleName') }}
              </p>
            </div>
          </template>
        </VueperSlide>
      </CustomCarousel>
    </template>
  </BaseContentCard>

  <WidgetEmptyCard
    v-else
    :emptyKey="emptyKey"
    :backgroundColor="colorHex"
    :icon="icon"
    iconType="fas"
  />
</template>
