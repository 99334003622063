<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { get } from 'lodash';
import { useRoute, useRouter } from 'vue-router';

import CoverView from '@views/shared/CoverView.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';
import MediaIcons from '@components/shared/MediaIcons.vue';
import MediaList from '@views/shared/MediaList.vue';
import MediaPreview from '@views/overlays/MediaPreview.vue';
import ProfilesList from '@components/shared/ProfilesList.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();

const showSelectDepartment = ref(false);
const showMediaList = ref(false);
const mediaType = ref('');
const isLoading = ref(true);
const selectedMediaIndex = ref(-1);
const hideMediaPreview = ref(false);
const selectedDepartmentId = ref(-1);

const departments = computed(() => store.getters['institution/departments']);
const mainDepartmentId = computed(() => store.getters['department/id']);
const myArchive = computed(() => store.getters['profiles/myArchive']);
const selectedProfile = computed(() => store.getters['profiles/activeProfile']);

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const selectedDepartment = computed(() => {
  return departments.value.find(
    (department) =>
      parseInt(department.id) === parseInt(selectedDepartmentId.value)
  );
});

const coverImage = computed(() => {
  return get(
    selectedDepartment.value,
    'presentation.image',
    '/assets/img/placeholder.png'
  );
});

const description = computed(() => {
  return get(selectedDepartment.value, 'presentation.description', '');
});

const documents = computed(() => {
  return myArchive?.value?.documents || [];
});

const mediaIcons = computed(() => {
  return [
    {
      name: 'documents',
      fa: 'file-alt',
      amount: documents.value.length || 0,
    },
  ];
});

const showMediaPreview = computed(() => {
  return selectedMediaIndex.value > -1 && !hideMediaPreview.value;
});

onMounted(async () => {
  await store.dispatch('general/setActiveContentView', {});
  switchDepartment({
    id: mainDepartmentId.value,
    type: 'DEPARTMENT',
  });
  isLoading.value = false;
});

function closeMediaList() {
  mediaType.value = '';
  selectedMediaIndex.value = -1;
  showMediaList.value = false;
}

async function switchDepartment(department) {
  const departmentId = parseInt(department.id);
  selectedDepartmentId.value = departmentId;
  await store.dispatch('profiles/getMyArchive', {
    id: departmentId,
    type: 'DEPARTMENT',
  });
  showSelectDepartment.value = false;
}

async function showSingleProfile(profile) {
  await store.dispatch('profiles/setActiveProfile', profile);
  router.push(`/ourHome/${profile.id}`);
}

function mediaIconSelected(selectedMediaType) {
  mediaType.value = selectedMediaType;

  if (documents.value.length === 1) {
    // if only one item, go straight to media-preview
    selectedMediaIndex.value = 0;
    hideMediaPreview.value = false;
    return;
  }

  showMediaList.value = true;
}
</script>

<template>
  <div>
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="documents"
      :allow-archive-remove="!!selectedProfile.id || false"
      @close-preview="selectedMediaIndex = -1"
    />

    <cover-view
      v-if="!isLoading"
      :gradient="true"
      :image-url="coverImage"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div>
        <media-icons
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="documents.length"
          @media-selected="mediaIconSelected"
        />
        <div v-if="!showMediaList" class="h-full flex flex-col">
          <div class="mx-20">
            <div class="largeListItemHeader pt-20 mb-10">
              {{ selectedDepartment.name }}
            </div>

            <description-block :description="description" />
          </div>

          <profiles-list
            :can-close="false"
            :selected-profile-type="selectedProfile.type"
            :shared-departments="departments"
            @profile-selected="showSingleProfile($event)"
            @department-selected="switchDepartment"
          />
        </div>

        <div v-else class="p-20">
          <media-list
            :items="documents"
            :media-type="mediaType"
            class="pt-20"
            :can-close="true"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>
    </cover-view>
  </div>
</template>

<style lang="scss">
.profile {
  .profileImage {
    &.small {
      width: 96px;
      height: 96px;
    }

    &.large {
      width: 180px;
      height: 180px;
    }
  }
}
</style>
