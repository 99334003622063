<template>
  <div>
    <div
      v-if="itemType === 'activity' || itemType === 'meal'"
      class="grid-view grid grid-cols-3 p-10 gap-14"
    >
      <card-advanced
        v-for="(item, index) in items"
        :key="index"
        :card-type="activeContentView.cardType"
        :title="item.title"
        :start-date="item.startDate"
        :meta="
          !useExternalMeals
            ? { icon: item.meta.icon, text: item.meta.text }
            : null
        "
        :is-now="isItemNow(item)"
        :is-cancelled="item.cancelled"
        :image="getCoverImage(item)"
        :gradient="true"
        class="shadow-m"
        @card-clicked="$emit('item-selected', item)"
      />
    </div>

    <div v-else class="grid-view grid grid-cols-3 p-10 gap-14">
      <card-advanced
        v-for="(item, index) in items"
        :key="index"
        :card-type="
          itemType === 'gallery' ? 'vertical' : activeContentView.cardType
        "
        :title="item.title"
        :meta="{ icon: item.meta.icon, text: item.meta.text }"
        :image="getCoverImage(item)"
        :gradient="true"
        class="shadow-m"
        @card-clicked="$emit('item-selected', item)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardAdvanced from '@components/cards/Advanced.vue'
import { isItemNow, formatTime } from '@utils/helpers.js'
import { get } from 'lodash'

export default {
  components: {
    CardAdvanced,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemType: {
      type: String,
      default: 'activity',
    },
  },
  computed: {
    ...mapGetters({
      activeContentView: 'general/activeContentView',
      institutionSettings: 'institution/settings',
    }),
    useExternalMeals() {
      return get(this.institutionSettings, 'useExternalMeals', false)
    },
    moduleColor() {
      return this.$route?.meta?.color
    },
  },
  methods: {
    formatTime(item) {
      return formatTime(item.startDate)
    },
    isItemNow(item) {
      return isItemNow(item.startDate, item.endDate)
    },
    getCoverImage(item) {
      return get(item, 'coverImage.source', '/img/placeholder.png')
    },
  },
}
</script>
