import store from '@store';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export default {
  isTokenValid() {
    const tokenTTL = localStorage.getItem('api_token_ttl', 0);
    const created = new Date(
      localStorage.getItem('api_token_created')
    ).getTime();
    const expiry = created + tokenTTL * 1000;
    const now = new Date().getTime();

    const isValid = expiry > now;

    if (!isValid) {
      localStorage.removeItem('api_token');
      localStorage.removeItem('api_token_ttl');
      localStorage.removeItem('department_data');
      localStorage.removeItem('dimmer');
      localStorage.removeItem('api_token_ttl');
      localStorage.removeItem('api_token_created');
    }

    return isValid;
  },
  async getDepartment(loginKey) {
    const data = (
      await axios.get(
        `${import.meta.env.VITE_LOOPBACK_API_URL}/Departments/${loginKey}/login`
      )
    ).data;

    if (!data.id) {
      throw new Error('No data found for department with loginKey ', loginKey);
    }

    localStorage.setItem('api_token', data.id);
    localStorage.setItem('api_token_ttl', data.ttl);
    localStorage.setItem('api_token_created', data.created);
    localStorage.setItem('department_data', JSON.stringify(data.department));
    Sentry.setUser({ id: data.department.id });

    store.dispatch('department/setDepartmentId', data.department.id);
  },
  async authenticate() {
    let tempKey = sessionStorage.getItem('temp_login_key');

    // Handle case when session storage reads null value as string.
    if (tempKey === 'null') {
      tempKey = null;
    }

    const mainKey =
      new URLSearchParams(window.location.search).get('loginKey') ||
      sessionStorage.getItem('main_login_key');

    sessionStorage.setItem('main_login_key', mainKey);

    let loginKey = tempKey || mainKey;
    localStorage.setItem('login_key', loginKey);

    const departmentData = JSON.parse(localStorage.getItem('department_data'));

    if (this.isTokenValid() && departmentData) {
      await store.dispatch('department/setDepartmentId', departmentData.id);
      return true;
    }

    if (loginKey && loginKey.length) {
      await this.getDepartment(loginKey);
    } else {
      throw new Error('No loginKey provided');
    }
  },
};
