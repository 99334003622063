<script setup>
import { computed } from 'vue';
import { get } from 'lodash';
import { useStore } from 'vuex';

const store = useStore();

const data = computed(() => {
  return store.getters['general/activeOverlay'].data;
});

function getDepartmentName(profile) {
  return get(profile, 'departments[0].name', '');
}
</script>

<template>
  <div>
    <div class="flex justify-between self-center px-20 py-20 rounded-t-3xl">
      <div class="largeListItemHeader">
        {{ $t(data.title) }}
      </div>
      <fa-icon
        class="text-5xl"
        :icon="['fal', 'times']"
        @click="$emit('close-overlay', true)"
      />
    </div>

    <div
      v-for="(profile, index) in data.profiles"
      :key="index"
      class="participants content inline-flex w-full pb-10 px-20"
    >
      <img
        :src="profile.displayImage"
        :class="{ '-m-1': index !== 0 }"
        class="w-24 h-24 rounded-full border border-gray-200 mr-10"
      />
      <div class="details">
        <p class="label">
          {{ profile.displayName }}
        </p>
        <span>
          <fa-icon :icon="['fal', 'house-user']" />
          {{ getDepartmentName(profile) }}
        </span>
      </div>
    </div>
  </div>
</template>
