<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { isFuture } from 'date-fns';
import { formatDistanceToNow } from '@utils/date-fns';
import { default as ButtonEl } from '@components/shared/Button.vue';
import i18n from '@i18n';
import { get, isEmpty } from 'lodash';
import CoverView from '@views/shared/CoverView.vue';
import MediaIcons from '@components/shared/MediaIcons.vue';
import MediaPreview from '@views/overlays/MediaPreview.vue';
import MediaList from '@views/shared/MediaList.vue';
import PinFlow from '@utils/pinFlow';
import DateTimeBlock from '@components/shared/blocks/DateTimeBlock.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';
import DepartmentsBlock from '@components/shared/blocks/DepartmentsBlock.vue';
import PeopleBlock from '@components/shared/blocks/PeopleBlock.vue';
import TitleBlock from '@components/shared/blocks/TitleBlock.vue';
import DeadlineBlock from '@components/shared/blocks/DeadlineBlock.vue';
import InstitutionBlock from '@components/shared/blocks/InstitutionBlock.vue';
import Tts from '@components/shared/Tts.vue';

const store = useStore();
const route = useRoute();

const opt = ref('');
const mediaType = ref('');
const selectedMediaIndex = ref(-1);
const hideMediaPreview = ref(false);
const showMediaList = ref(false);

const allMeals = computed(() => {
  return store.getters['meals/all'];
});

const selectedMeal = computed(() => {
  return store.getters['meals/selected'];
});

const activeProfile = computed(() => {
  return store.getters['profiles/activeProfile'];
});

const pinValidation = computed(() => {
  return store.getters['profiles/pinValidation'];
});

const institutionSettings = computed(() => {
  return store.getters['institution/settings'];
});

const departmentSettings = computed(() => {
  return store.getters['department/settings'];
});

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const useExternalMeals = computed(() => {
  return get(institutionSettings.value, 'useExternalMeals', false);
});

const imageUrl = computed(() => {
  return get(
    selectedMeal.value,
    'coverImage.source',
    '/img/placeholder.png'
  );
});

const signupPossible = computed(() => {
  if (!get(departmentSettings.value, 'meal.signup', true)) return false;
  return selectedMeal.value.signupPossible && !selectedMeal.value.cancelled;
});

const optoutPossible = computed(() => {
  if (!get(departmentSettings.value, 'meal.signup', true)) return false;
  return isFuture(new Date(selectedMeal.value.startDate));
});

const mediaIcons = computed(() => {
  return [
    {
      name: 'pictures',
      fa: 'image',
      amount: selectedMeal.value.pictures.length,
    },
    {
      name: 'videos',
      fa: 'film',
      amount: selectedMeal.value.videos.length,
    },
  ];
});

const mediaItems = computed(() => {
  return mediaType.value === 'pictures'
    ? selectedMeal.value.pictures
    : selectedMeal.value.videos;
});

const selectedMediaItem = computed(() => {
  return allMeals.value[selectedMediaIndex.value];
});

const showMediaPreview = computed(() => {
  return !isEmpty(selectedMediaItem.value) && !hideMediaPreview.value;
});

const isAlreadyOptedInOut = computed(() => {
  const participantIds = selectedMeal.value.participants.map(
    (participant) => participant.id
  );

  return participantIds.includes(activeProfile.value.id);
});

const signupExpired = computed(() => {
  return (
    !selectedMeal.value.signupPossible &&
    !isBefore(new Date(), new Date(selectedMeal.value.signupEnd))
  );
});

watch(pinValidation, (pinData) => {
  if (!pinData.validPin || !pinData.pin) return;

  const signupData = {
    subscription: {
      id: selectedMeal.value.id,
    },
    profile: {
      profileId: activeProfile.value.id,
      profileType: activeProfile.value.type,
      pin: pinData.pin.toString(),
    },
    opt: opt.value,
  };

  if (
    (opt.value === 'in' && !isAlreadyOptedInOut.value) ||
    (opt.value === 'out' && isAlreadyOptedInOut.value)
  ) {
    store.dispatch('meals/mealSignupScreen', signupData);
  }
});

onMounted(() => {
  store.dispatch('meals/getSelectedMeal', route.params.id);
  window._paq.push(['trackPageView']);
});

function formatDeadline(meal) {
  return `${i18n.global.t('global.signUpDeadline')} ${formatDistanceToNow(new Date(meal.signupEnd), { addSufix: true })}`;
}

function showProfiles(profileType) {
  window._paq.push(['trackEvent', 'ProfilesListClicked', profileType]);
  store.dispatch('general/setActiveOverlay', {
    name: 'participants-list',
    data: {
      title: `global.${profileType}`,
      profiles: selectedMeal.value[profileType],
    },
  });
}

async function optInOrOut(option) {
  opt.value = option;

  const flow = new PinFlow({
    entity: 'meals',
    text: 'meals.theMeal',
    opt: option,
    title: selectedMeal.value.title,
    participants: option === 'in' ? [] : selectedMeal.value.participants,
    sharedDepartments: selectedMeal.value.departments,
  });

  flow.startSignupFlow('meals');
}

function closeMediaList() {
  mediaType.value = '';
  selectedMediaIndex.value = -1;
  showMediaList.value = false;
}

function mediaIconSelected(payload) {
  mediaType.value = payload;

  if (mediaItems.value.length === 1) {
    // if only one item, go straight to media-preview
    selectedMediaIndex.value = 0;
    hideMediaPreview.value = false;
    return;
  }

  showMediaList.value = true;
}
</script>

<template>
  <div class="meal">
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      :shared-departments="selectedMeal.departments"
      @close-preview="selectedMediaIndex = -1"
    />

    <cover-view
      v-if="selectedMeal && selectedMeal.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="mealContent tts-content">
        <media-icons
          v-if="!useExternalMeals"
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="mediaItems.length"
          @media-selected="mediaIconSelected"
        />
        <tts entity-type="MEAL" />

        <div v-if="!showMediaList">
          <TitleBlock
            margin="pt-14 mb-14"
            :text="selectedMeal.title"
          ></TitleBlock>

          <div class="mealDetails">
            <dateTimeBlock
              v-if="!useExternalMeals"
              :start="selectedMeal.startDate"
              :end="selectedMeal.endDate"
            />

            <span :aria-label="i18n.global.t('global.dishes')"></span>
            <div
              v-for="(part, index) in selectedMeal.parts"
              :key="index"
              class="content pb-10 flex items-center w-full"
            >
              <fa-icon class="mr-6" :icon="['fal', 'soup']" />
              <div>
                <p class="mb-4" :aria-label="part.title">
                  {{ part.title }}
                </p>
                <img
                  :src="part.picture.source"
                  class="mealPartImage rounded-2xl"
                />
              </div>
            </div>

            <div v-if="!useExternalMeals">
              <DescriptionBlock
                v-if="selectedMeal.description.length"
                :description="selectedMeal.description"
                icon="align-left"
              />
            </div>

            <DescriptionBlock
              v-else
              :description="selectedMeal.category"
              icon="align-left"
            />

            <DeadlineBlock
              v-if="selectedMeal.signupPossible"
              :signup-expired="signupExpired"
              :text="formatDeadline(selectedMeal)"
            />

            <div v-if="!useExternalMeals">
              <PeopleBlock
                v-if="selectedMeal.participants.length"
                text="global.participantsPlural"
                :module-color="moduleColor"
                :profiles="selectedMeal.participants"
                @show-people="showProfiles('participants')"
              />
            </div>

            <div v-if="!useExternalMeals">
              <DepartmentsBlock
                v-if="selectedMeal.departments.length"
                :departments="selectedMeal.departments"
              />
              <InstitutionBlock :title="selectedMeal.institution.name" />
            </div>
          </div>
        </div>

        <div v-else>
          <media-list
            :items="mediaItems"
            :media-type="mediaType"
            :can-close="true"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>

      <div
        v-if="!showMediaList"
        class="mealActions flex w-max left-full sticky"
      >
        <button-el
          v-if="signupPossible"
          text="global.signUp"
          icon="check"
          background-color="success"
          text-color="white"
          class="mr-5 shadow-xsm"
          @click="optInOrOut('in')"
        />

        <button-el
          v-if="optoutPossible && signupPossible"
          text="global.optOut"
          icon="minus-circle"
          background-color="error"
          text-color="white"
          class="shadow-xsm"
          @click="optInOrOut('out')"
        />
      </div>
    </cover-view>
  </div>
</template>

<style lang="scss">
.meal {
  .mealContent {
    &.participants {
      margin-top: 700px;
    }

    > *:not(.mediaIcons) {
      padding: 0 100px;
    }

    .mealPartImage {
      width: 480px;
      height: 270px;
      object-fit: cover;
    }
  }

  .mealActions {
    bottom: 70px;
    padding-right: 80px;
  }
}
</style>
