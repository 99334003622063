<template>
  <div
    id="app"
    :class="{ isLoading, 'bg-neutral-100': onStartPage }"
    class="flex flex-col relative"
  >
    <header-el />
    <div
      v-if="!isLoading"
      class="flex-1 relative overflow-y-scroll overflow-x-hidden route-wrapper"
      :class="
        !this.redesignedModulePaths.includes($route.path) ? routeClasses : ''
      "
    >
      <!-- remember to add redesigne modules to redesignedModulePaths -->
      <router-view
        :key="$route.path"
        :class="
          !this.redesignedModulePaths.includes($route.path)
            ? [
                { contentArea: !meta.subView },
                'h-full',
                'bg-white',
                'overflow-scroll',
              ]
            : 'bg-white overflow-scroll'
        "
      />
    </div>
    <footer-el @close-overlay="closeOverlay" />
    <transition
      enter-active-class="animate__animated animate__slideInUp"
      leave-active-class="animate__animated animate__slideOutDown"
      :duration="500"
    >
      <keyboard v-show="keyboardInput !== null" :input="keyboardInput" />
    </transition>

    <overlay
      :show="overlayHasValue && showOverlay"
      @close-overlay="closeOverlay"
    />
    <dimmer-overlay v-if="onStartPage" />
    <idle-watcher v-if="idleTimeout > 0" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isEmpty, get, cloneDeep } from 'lodash';
import { subDays, addDays } from 'date-fns';
import Header from '@components/shared/Header.vue';
import ModuleList from '@components/shared/ModuleList.vue';
import Footer from '@components/footer/Footer.vue';
import Keyboard from '@components/shared/Keyboard.vue';
import Overlay from '@views/overlays/Overlay.vue';
import DimmerOverlay from '@components/dimmer/DimmerOverlay.vue';
import i18n from '@i18n';
import IdleWatcher from '@utils/idleWatcher.vue';
import auth from '@utils/auth';
import BaseContentCard from '@components/cards/baseContentCard/BaseContentCard.vue';
import MealPlanModule from './js/views/meals/MealPlanModule.vue';

export default {
  components: {
    MealPlanModule,
    BaseContentCard,
    HeaderEl: Header,
    ModuleList,
    Keyboard,
    Overlay,
    IdleWatcher,
    FooterEl: Footer,
    DimmerOverlay,
  },
  data() {
    return {
      showOverlay: false,
      idle: {},
      institution: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'general/isLoading',
      keyboardInput: 'general/keyboardInput',
      activeOverlay: 'general/activeOverlay',
      nextActiveOverlay: 'general/nextActiveOverlay',
      activeViewType: 'general/activeViewType',
      activecontentView: 'general/activecontentView',
      dimmer: 'general/dimmer',
      activePinFlow: 'general/activePinFlow',
      institutionLocale: 'institution/locale',
      institutionId: 'institution/id',
      departmentId: 'department/id',
      isIdle: 'idleHandler/isIdle',
      institutionSettings: 'institution/settings',
      departmentSettings: 'department/settings',
      idleTimeout: 'department/idleTimeout',
    }),
    // TODO: https://proreact.atlassian.net/browse/IBG-5793 Temporary solution to avoid breaking old modules while implementing new design.
    redesignedModulePaths() {
      return [
        '/mealPlan',
        '/activities',
        '/externalActivities',
        '/galleries',
        '/meetings',
        '/news',
        '/bookings',
      ];
    },
    onStartPage() {
      return this.$route.path === '/';
    },
    meta() {
      return this.$route.meta || {};
    },
    routeClasses() {
      return {
        'scroll-up': this.meta.scrollUp && !this.meta.subView,
      };
    },
    overlayHasValue() {
      return !isEmpty(this.activeOverlay);
    },
    workScheduleRanges() {
      // If the department has checked overwrite institution setting it will be in the shift array.
      const matchingDepartment = this.institutionSettings?.shift?.find(
        ({ departmentId }) => departmentId == this.departmentId
      );
      const now = new Date();

      // Subtract 1 because the core admin setting is not 0 based but 1 should mean 1 days forward.
      const daysForward =
        get(
          matchingDepartment,
          'daysForward',
          this.departmentSettings.shift.daysForward
        ) - 1;

      return {
        from: subDays(now, daysForward),
        to: addDays(now, daysForward),
      };
    },
    availableModules() {
      return cloneDeep(this.departmentSettings).screenConfig.modules.map(
        (module) => module.type
      );
    },
  },
  watch: {
    activeOverlay(newValue) {
      if (!isEmpty(newValue)) {
        this.showOverlay = true;
      }
    },
    async isIdle(newValue) {
      const mainLoginKey = sessionStorage.getItem('main_login_key');
      const tempLoginKey = sessionStorage.getItem('temp_login_key');
      // If theres a difference we are currently not on the main department so switch back.
      if (tempLoginKey && mainLoginKey !== tempLoginKey) {
        await this.$store.dispatch('department/switchDepartment');
        return;
      }

      this.$store.dispatch('galleries/setSelectedCategory', '');

      if (this.$route.path !== '/') {
        this.$router.push('/');
      }

      if (newValue === true) {
        this.fetchData();
      }
    },
    $route(newValue) {
      this.$nextTick(async () => {
        this.closeOverlay(true);
        if (newValue.meta.scrollUp) {
          this.scrollToContent('scroll-up', 1200);
        } else {
          this.scrollToContent('route-wrapper', 150);
        }

        if (newValue.fullPath === '/') {
          // Prevent fetching with old token before dep. switch reload.
          if (!this.isLoading) {
            this.fetchData();
          }
          this.$store.dispatch('profiles/setActiveProfile', {});
        }
      });
    },
  },
  async mounted() {
    await auth.authenticate();

    try {
      await this.$store.dispatch('institution/getInstitution');
      await this.$store.dispatch('department/getDepartment');
      this.fetchData();
      i18n.global.locale = this.institutionLocale;
    } catch (err) {
      throw new Error('Could not get institution/department data, retrying');
    }

    const DIMENSION_INSTITUTION = 1;
    const DIMENSION_DEPARTMENT = 2;

    if (this.$matomo) {
      this.$matomo.setCustomDimension(
        DIMENSION_INSTITUTION,
        this.institutionId
      );
      this.$matomo.setCustomDimension(DIMENSION_DEPARTMENT, this.departmentId);
    }

    this.$store.dispatch('general/setLoading', false);
  },
  methods: {
    async onIdle() {
      if (sessionStorage.getItem('temp_login_key')) {
        await this.$store.dispatch('department/switchDepartment');
        return;
      }

      if (this.$route.path !== '/') {
        this.$router.push('/');
      } else {
        this.fetchData();
      }
    },
    async fetchData() {
      this.$store.dispatch('general/setCurrentDate', new Date());

      try {
        await Promise.all([
          this.$store.dispatch('institution/getExternalConnections'),
          this.$store.dispatch('profiles/getProfiles'),
        ]);

        const promises = [];

        if (this.availableModules.includes('GALLERY')) {
          promises.push(this.$store.dispatch('galleries/getGalleries'));
        }

        if (this.availableModules.includes('SLIDESHOW')) {
          promises.push(this.$store.dispatch('galleries/getGalleries'));
        }

        if (
          this.availableModules.includes('ACTIVITIES') ||
          this.availableModules.includes('EXTERNAL_ACTIVITIES')
        ) {
          promises.push(this.$store.dispatch('activities/getActivities'));
        }

        if (this.availableModules.includes('NEWS')) {
          promises.push(this.$store.dispatch('news/getNews'));
        }

        if (this.availableModules.includes('MEAL_PLAN')) {
          if (get(this.institutionSettings, 'useExternalMeals', false)) {
            promises.push(this.$store.dispatch('meals/getPcdMeals'));
          } else {
            promises.push(this.$store.dispatch('meals/getMeals'));
          }
        }

        if (this.availableModules.includes('BOOKING')) {
          promises.push(
            this.$store.dispatch('bookingResources/getBookingResources')
          );
        }

        if (this.availableModules.includes('MEETINGS')) {
          promises.push(this.$store.dispatch('meetings/getMeetings'));
        }

        promises.push(
          this.$store.dispatch(
            'workSchedule/getWorkSchedules',
            this.workScheduleRanges
          )
        );
        promises.push(this.$store.dispatch('workSchedule/setType'));

        if (this.availableModules.includes('JOURNEY_PLANNER')) {
          promises.push(this.$store.dispatch('journeyPlanner/getJourneyPlan'));
        }

        await Promise.all(promises);
      } catch (err) {
        throw new Error(`Could not fetch module data, ${err}`);
      }
      this.$store.dispatch('general/setLoading', false);
    },
    async closeOverlay(force = false) {
      this.$store.commit('idleHandler/SET_IS_PAUSED', false); // Always start idle checks whenever overlay closes.
      if (force) {
        await this.$store.dispatch('general/setActiveOverlay', {});
        await this.$store.dispatch('general/setNextActiveOverlay', {});
        await this.$store.dispatch('profiles/setPinValidation', {});
        await this.$store.dispatch('general/setActivePinFlow', '');
      }

      const hasNextOverlay = !isEmpty(this.nextActiveOverlay);
      this.showOverlay = false;

      if (hasNextOverlay) {
        setTimeout(() => {
          this.$store.dispatch(
            'general/setActiveOverlay',
            this.nextActiveOverlay
          );
          this.showOverlay = true;
          this.$store.dispatch('general/setNextActiveOverlay', {});
        }, this.$constants.animationDuration); // wait for the transition to end before we change the store value
      }
    },
    scrollToContent(className, xValue) {
      const selector = document.querySelector(`.${className}`);
      if (selector) selector.scrollTop = xValue;
    },
  },
};
</script>

<style lang="scss">
.tick-credits {
  display: none !important;
  opacity: 0 !important;
}

#app {
  width: 1080px;
  height: 1920px;
  margin: auto;
  background: 'white';

  .content-views {
    transition: opacity 500ms;
  }

  .route-wrapper {
    .contentArea {
      animation-duration: 1.3s;

      .largeListItemHeader {
        border-radius: 25px 25px 0 0;
      }
    }

    .simple-keyboard {
      animation-duration: 0.5s;
    }

    &.scroll-up .contentArea {
      margin-top: 1200px;
    }
  }
}
</style>
