<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { get, cloneDeep } from 'lodash';
import GridView from '@components/shared/GridView.vue';
import i18n from '@i18n';

const store = useStore();
const route = useRoute();
const selectedMediaIndex = ref(-1);
const emit = defineEmits(['close', 'item-selected']);

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  mediaType: {
    type: String,
    default: 'pictures',
  },
  canClose: {
    type: Boolean,
    default: false,
  },
});

const activeOverlay = computed(() => store.getters['general/activeOverlay']);
const institutionSettings = computed(
  () => store.getters['institution/settings']
);

const showMediaPreview = computed(() => {
  if (route.path === '/gallery') return false;
  return selectedMediaIndex.value > -1 || amount.value < 2;
});

const useExternalMedia = computed(() => {
  return get(institutionSettings.value, 'useExternalMedia', false);
});

const amount = computed(() => {
  return items.value.length;
});

const data = computed(() => {
  return {
    items: get(this, 'activeOverlay.data.items', props.items),
    mediaType: get(this, 'activeOverlay.data.mediaType', props.mediaType),
  };
});

const moduleColor = computed(() => {
  return route?.meta?.color;
});

function close() {
  window._paq.push(['trackEvent', 'Navigation', 'CloseOverlayX']);
  emit('close');
}

function mediaSelected(index) {
  emit('item-selected', index);
}

function addMetaToGalleries(galleries) {
  const clonedGalleries = cloneDeep(galleries);

  return clonedGalleries.map((gallery) => {
    return {
      ...gallery,
      meta: {
        icon: 'images',
        text: `${
          !gallery.videos.length && !useExternalMedia.value
            ? i18n.global.t('global.images')
            : i18n.global.t('global.imagesAndVideos')
        } ${
          useExternalMedia.value
            ? gallery.media.length
            : gallery.pictures.length
        }`,
      },
    };
  });
}

function showSingleGallery(gallery) {
  store.dispatch('general/setActiveOverlay', {
    name: 'our-home-gallery',
    data: {
      title: gallery.title,
      gallery: useExternalMedia.value ? gallery.media : gallery,
    },
  });
}
</script>

<template>
  <div
    class="media-list"
    :class="{
      galleries:
        data.mediaType === 'galleries' || data.mediaType === 'externalMedia',
    }"
  >
    <!-- Title -->
    <div
      class="flex w-full h-12 justify-between"
      :class="{ galleriesMargin: data.mediaType === 'galleries' }"
    >
      <fa-icon
        v-if="canClose"
        :icon="['fal', 'times']"
        class="text-5xl h-auto font-bold"
        @click="close"
      />
    </div>

    <!-- Pictures -->
    <div
      v-if="data.mediaType === 'pictures'"
      class="grid grid-cols-2 gap-3 object-cover"
    >
      <div v-if="!data.items.length" class="header">
        {{ $t('media.noImages') }}
      </div>

      <img
        v-lazy
        v-for="(image, index) in data.items"
        :key="index"
        :data-src="image.source"
        src="/img/blurhash.png"
        class="object-cover h-80 w-full"
        @click="mediaSelected(index)"
      />
    </div>

    <!-- Galleries -->
    <div
      v-if="
        data.mediaType === 'galleries' || data.mediaType === 'externalMedia'
      "
      class="galleries"
    >
      <div v-if="!data.items.length" class="header">
        {{ $t('media.noImages') }}
      </div>

      <grid-view
        class="pt-0"
        :items="addMetaToGalleries(data.items)"
        item-type="gallery"
        @item-selected="showSingleGallery($event)"
      />
    </div>

    <!-- Videos -->
    <div v-else-if="data.mediaType === 'videos'" class="w-full">
      <div v-if="!data.items.length" class="header">
        {{ $t('media.noVideos') }}
      </div>
      <div
        v-for="(video, index) in data.items"
        :key="index"
        class="video w-full mb-3 relative"
        @click="mediaSelected(index)"
      >
        <div
          class="rounded-full bg-white absolute w-20 h-20 right-10 bottom-10 flex items-center justify-center"
        >
          <fa-icon :icon="['fal', 'caret-right']" class="text-5xl" />
        </div>
        <img :src="video.thumbnail" class="w-full h-full object-cover" />
      </div>
    </div>

    <!-- Documents -->
    <div v-else-if="mediaType === 'documents'" class="object-cover">
      <div v-if="!data.items.length" class="header">
        {{ $t('media.noDocuments') }}
      </div>

      <div
        v-for="(document, index) in data.items"
        :key="index"
        class="flex items-center justify-between mb-12"
        @click="mediaSelected(index)"
      >
        <div class="flex items-center">
          <fa-icon class="mr-12 text-4xl" :icon="['fal', 'file-alt']" />
          <p class="font-bold" :class="`text-${moduleColor}`">
            {{ document.title }}
          </p>
        </div>

        <fa-icon class="text-4xl self-end" :icon="['fal', 'angle-right']" />
      </div>
    </div>

    <!-- Media -->
    <div v-else>
      <div
        v-if="data.mediaType === 'media'"
        class="grid grid-cols-2 gap-3 object-cover"
      >
        <div v-if="!data.items.length" class="header">
          {{ $t('media.noImages') }}
        </div>

        <div v-for="(item, index) in data.items" :key="index">
          <img
            v-if="item.__typename === 'GalleryPicture'"
            :src="item.source"
            class="object-cover h-80 w-full"
            @click="mediaSelected(index)"
          />

          <div v-else class="relative">
            <div
              class="rounded-full bg-white absolute w-20 h-20 right-10 bottom-10 flex items-center justify-center"
            >
              <fa-icon :icon="['fal', 'caret-right']" class="text-5xl" />
            </div>

            <img
              :src="item.thumbnail"
              class="w-full object-cover h-80"
              @click="mediaSelected(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
