<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import 'vueperslides/dist/vueperslides.css';
import { getModuleData } from '@utils/modules';
import BaseWidget from '@components/widgets/BaseWidget.vue';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const navigateToModule = (item) => {
  const path = item ? `/${routeName}/${item.id}` : `/${routeName}`;
  router.push(path);
};

const { colorHex, icon, name, emptyKey, routeName } = getModuleData().GALLERY;

const galleries = computed(() => {
  return store.getters['galleries/all'].filter((gallery) => {
    if (gallery?.showOnFrontpage === false) {
      return;
    }
    if (useExternalMedia.value) {
      return !isEmpty(gallery.settings.dreambrokerUrl);
    }
    return gallery;
  });
});

const useExternalMedia = computed(() => {
  return store.getters['institution/settings']?.useExternalMedia;
});

const isWideWidget = computed(() => {
  const { frWidth } = store.getters[
    'department/settings'
  ]?.screenConfig?.modules?.find((module) => module.name === name);
  return frWidth >= 2;
});
</script>

<template>
  <BaseWidget
    @slide-pressed="navigateToModule($event)"
    :name="name"
    :routeName="routeName"
    :widgetData="galleries"
    :backgroundColor="colorHex"
    :icon="icon"
    iconType="fas"
    :emptyKey="emptyKey"
    :isWideWidget="isWideWidget"
    :shouldShowMediaCount="true"
  />
</template>
