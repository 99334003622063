<script setup>
import { computed, ref } from 'vue';
import { isToday } from 'date-fns';
import { useStore } from 'vuex';
import { useSwipe } from '@vueuse/core';
import 'vueperslides/dist/vueperslides.css';
import { VueperSlides, VueperSlide } from 'vueperslides';
import WidgetContentCard from '@components/cards/widgetContentCard/WidgetContentCard.vue';
import WidgetEmptyCard from '@components/cards/widgetEmptyCard/WidgetEmptyCard.vue';
import { isItemNow } from '@utils/helpers.js';

const props = defineProps({
  isWideWidget: {
    type: Boolean,
    required: true,
    default: false,
  },
  routeName: {
    type: String,
    required: true,
  },
  widgetData: {
    required: false,
    type: [
      {
        id: {
          required: true,
          type: String,
        },
        coverImage: {
          type: {
            source: {
              type: String,
              required: true,
              default: '/img/placeholder.jpeg',
            },
          },
          required: true,
        },
        title: {
          type: String,
          required: true,
        },
        startDate: {
          type: String,
          required: true,
        },
        endDate: {
          type: String,
          required: true,
        },
        cancelled: {
          type: Boolean,
          required: true,
          default: false,
        },
      },
    ],
  },
  backgroundColor: {
    type: String,
    required: true,
    default: 'red',
  },
  icon: {
    type: String,
    required: true,
    default: 'home',
  },
  iconType: {
    type: String,
    required: false,
    default: 'far',
  },
  emptyKey: {
    type: String,
    required: true,
  },
  shouldShowMediaCount: {
    type: Boolean,
    default: false,
  },
  shouldShowDate: {
    type: Boolean,
    default: true,
  },
  isExternal: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const slider = ref();
const store = useStore();
const swipingDirection = ref();
const SMALL_WIDGET_BULLET_AMOUNT = 5;
const LARGE_WIDGET_BULLET_AMOUNT = 10;
const CAROUSEL_DEFAULT_DURATION = 20000;
const emit = defineEmits(['slide', 'ready', 'slide-pressed']);

const shouldSlide = computed(() => props.widgetData?.length > 1);

const slicedWidgetData = computed(() => {
  return props.isWideWidget
    ? props.widgetData?.slice(0, LARGE_WIDGET_BULLET_AMOUNT)
    : props.widgetData?.slice(0, SMALL_WIDGET_BULLET_AMOUNT);
});

const carouselDuration = computed(() => {
  return (
    store.getters['department/settings']?.screenConfig?.carouselDuration *
      1000 || CAROUSEL_DEFAULT_DURATION
  );
});

// We need to override the touch in Vueperslides as our screens don't respond to normal touch / click events
const { direction, lengthX } = useSwipe(slider, {
  passive: true,
  onSwipe() {
    swipingDirection.value = direction.value;
  },
  onSwipeEnd() {
    if (swipingDirection.value === 'left' && lengthX.value >= 50) {
      slider.value.next();
    } else if (lengthX.value <= -50) {
      slider.value.previous();
    }
  },
});
</script>

<template>
  <VueperSlides
    v-if="widgetData.length >= 1"
    ref="slider"
    @slide="emit('slide', $event.currentSlide.index)"
    @ready="emit('ready')"
    class="no-shadow"
    :disable="!shouldSlide"
    :infinite="shouldSlide"
    :autoplay="shouldSlide"
    :gap="3"
    :fade="false"
    :arrows="false"
    :bullets="true"
    :fixedHeight="true"
    :preventYScroll="true"
    :pause-on-touch="false"
    :pause-on-hover="false"
    :transitionSpeed="1100"
    :draggingDistance="20000"
    :duration="carouselDuration"
  >
    <VueperSlide
      v-for="item in slicedWidgetData"
      :key="`widget-slide-${item.title}`"
      :title="item.title"
      @click="emit('slide-pressed', item)"
    >
      <template #content>
        <WidgetContentCard
          :icon="icon"
          :iconType="iconType"
          :backgroundColor="backgroundColor"
          :imgSrc="item.coverImage?.source"
          :title="item.title"
          :isExternal="isExternal"
          :date="item.startDate"
          :isCancelled="item.cancelled"
          :isToday="isToday(item.startDate)"
          :isNow="isItemNow(item.startDate, item.endDate)"
          :picturesCount="item.pictures?.length"
          :videosCount="item.videos?.length"
          :mediaCount="item.media?.length"
          :shouldShowMediaCount="shouldShowMediaCount"
          :shouldShowDate="shouldShowDate"
        />
      </template>
    </VueperSlide>

    <template #bullet="{ active }">
      <div
        class="select-all m-2"
        :class="
          active
            ? 'h-[18px] w-[18px] bg-neutral-0 ring-2 ring-neutral-600 rounded-full ease-in-out duration-500'
            : 'h-[12px] w-[12px] bg-neutral-300A rounded-full ease-in-out duration-500'
        "
      />
    </template>
  </VueperSlides>

  <WidgetEmptyCard
    v-else
    @click="emit('slide-pressed')"
    :emptyKey="emptyKey"
    :backgroundColor="backgroundColor"
    :icon="icon"
    :iconType="iconType"
    :isExternal="isExternal"
  />
</template>

<style>
.vueperslides__bullets button,
.vueperslides__bullet {
  user-select: all !important;
  -moz-user-select: all !important;
  -webkit-user-select: auto !important;
}

.vueperslides__bullet {
  margin: 0 !important;
}
/* 
    Bullet container position absolute to allow pseudo element to work
    Excluding components with the exclude-style class to support old widgets since we have to edit vueperslide styles globally.
   */
.vueperslides__bullets:not(.exclude-style *) {
  max-height: 32px;
  min-height: 32px;
  position: relative;
  bottom: 204px;
  z-index: 100;
  flex-direction: row;
  align-items: center;
  width: min-content;
  margin: auto;
}

/* 
    Since we can't access the parent component we have to use a pseudo-element
    to add background opacity without affecting children.
  */
.vueperslides__bullets:not(.exclude-style *)::before {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  border-radius: 80px;
  background-color: #15151566;
}
</style>
