<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import 'vueperslides/dist/vueperslides.css';
import { getModuleData } from '@utils/modules';
import { getBookings } from '@utils/bookings';
import BaseWidget from '@components/widgets/BaseWidget.vue';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const navigateToModule = (item) => {
  if (item) {
    store.dispatch('bookingResources/setSelectedBookingEntry', item);
    router.push(`/${routeName}/${item.id}`);
  } else {
    router.push(`/bookings`);
  }
};

const { colorHex, icon, name, emptyKey, routeName } = getModuleData().BOOKING;

const bookings = computed(() => {
  const bookingRessources = store.getters['bookingResources/all'];
  return getBookings(bookingRessources);
});

const isWideWidget = computed(() => {
  const { frWidth } = store.getters[
    'department/settings'
  ]?.screenConfig?.modules?.find((module) => module.name === name);
  return frWidth >= 2;
});
</script>

<template>
  <BaseWidget
    @slide-pressed="navigateToModule($event)"
    :routeName="routeName"
    :widgetData="bookings"
    :backgroundColor="colorHex"
    :icon="icon"
    iconType="fas"
    :emptyKey="emptyKey"
    :isWideWidget="isWideWidget"
  />
</template>
