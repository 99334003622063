<template>
  <div
    :class="[ { 'shadow-m': shadow }, `bg-${color}` ]"
    class="text-white rounded-lg px-6 py-4 flex items-center justify-center"
  >
    <fa-icon
      class="text-white text-2xl mr-4"
      :icon="['fal', `${icon}`]"
    />
    <p class="font-bold text-2xl">
      {{ $t(text) }}
    </p>
  </div>
</template>

<script>


export default {
  props: {
    color: {
      type: String,
      'default': 'success'
    },
    text: {
      type: String,
      'default': 'global.now'
    },
    icon: {
      type: String,
      'default': 'bell'
    },
    shadow: {
      type: Boolean,
      'default': true
    }
  }
}
</script>