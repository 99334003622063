<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import 'vueperslides/dist/vueperslides.css';
import { getModuleData } from '@utils/modules';
import BaseWidget from '@components/widgets/BaseWidget.vue';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const navigateToModule = (item) => {
  const path = item ? `/${routeName}/${item.id}` : `/${routeName}`;
  router.push(path);
};

const { colorHex, icon, name, emptyKey, routeName } = getModuleData().NEWS;

const news = computed(() => {
  return store.getters['news/all'];
});

const isWideWidget = computed(() => {
  const { frWidth } = store.getters[
    'department/settings'
  ]?.screenConfig?.modules?.find((module) => module.name === name);
  return frWidth >= 2;
});
</script>

<template>
  <BaseWidget
    @slide-pressed="navigateToModule($event)"
    :routeName="routeName"
    :widgetData="news"
    :backgroundColor="colorHex"
    :icon="icon"
    iconType="fas"
    :emptyKey="emptyKey"
    :isWideWidget="isWideWidget"
    :shouldShowDate="false"
  />
</template>
