<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import {
  cleanLockedUsers,
  attemptFailed,
  isUserLocked,
} from '@composables/lockedUsers.js';

const store = useStore();
const emit = defineEmits(['close-overlay']);

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const colors = [
  '#DF2121',
  '#2BB914',
  '#2134DF',
  '#DFB521',
  '#DC0BC7',
  '#E87B17',
  '#5E21DF',
  '#953600',
  '#1FA3B5',
  '#929292',
];

const pin = ref([]);
const lockPinCode = ref(false);
const countDownInterval = ref({});
const validPin = ref(true);

const activeProfile = computed(() => store.getters['profiles/activeProfile']);
const activeOverlay = computed(() => store.getters['general/activeOverlay']);
const pinValidation = computed(() => store.getters['profiles/pinValidation']);
const institutionSettings = computed(
  () => store.getters['institution/settings']
);

const displayedCountDown = computed(() => {
  return Math.ceil(lockPinCode.value.duration / 60);
});

onMounted(() => {
  countDownInterval.value = setInterval(() => {
    cleanLockedUsers();
    lockPinCode.value = isUserLocked(activeProfile.value.id);
  }, 1000);

  window._paq.push(['trackEvent', 'EnterPin', 'Viewed']);
});

onUnmounted(() => {
  clearInterval(countDownInterval.value);
  store.dispatch('profiles/setPinValidation', {});
});

async function numberPressed(number) {
  if (pinValidation.value.validating) return;

  pin.value.push(number);

  if (pin.value.length < 4) return;

  validPin.value = await store.dispatch('profiles/validatePin', {
    profile: activeProfile.value,
    pin: pin.value.join(''),
  });

  if (!pinValidation.value.validPin) {
    attemptFailed(activeProfile.value.id, institutionSettings.value);
    resetPin();
    return;
  }

  emit('close-overlay');
}

function resetPin() {
  pin.value = [];
}

function switchUser() {
  emit('close-overlay');

  setTimeout(() => {
    store.dispatch('general/setActiveOverlay', {
      name: 'profile-select',
    });
    store.dispatch('general/setNextActiveOverlay', {
      name: 'enter-pin',
    });
  }, 700);
}
</script>

<style lang="scss">
.enter-pin {
  .validating {
    opacity: 0.5;
  }
}
</style>

<template>
  <div class="enter-pin h-full overflow-hidden">
    <div class="content flex flex-col h-full items-center">
      <div class="absolute -mt-96 w-96 flex flex-col items-center mb-20">
        <img
          :src="activeProfile.displayImage"
          class="rounded rounded-full w-60 h-60 border-8 border-white shadow-m object-cover bg-white mb-8"
        />

        <div class="largeListItemHeader text-center">
          {{ activeProfile.displayName }}
        </div>
      </div>

      <div class="mt-12 w-full flex justify-center">
        <div class="w-full h-1 bg-charcoal-xxlight" />

        <div
          class="w-60 rounded rounded-md bg-white -mt-10 p-6 flex items-center justify-center shadow-lg absolute"
        >
          <fa-icon class="text-3xl mr-3" :icon="['far', 'sign-out']" />

          <p class="font-bold text-2xl" @click="switchUser()">
            {{ $t('global.switchUser') }}
          </p>
        </div>
      </div>

      <div
        v-if="!pinValidation.validPin"
        class="flex flex-col flex-1 items-center p-10"
        :class="{ validating: pinValidation.validating }"
      >
        <div
          v-if="!lockPinCode.locked"
          class="largeListItemHeader mb-10 text-center error mt-8"
          :style="{
            opacity:
              pinValidation.validPin === false && !pinValidation.validating
                ? 1
                : 0,
          }"
        >
          {{ $t('modals.wrongPassword') }}
        </div>

        <div v-if="!lockPinCode.locked" class="content text-center mb-20">
          {{ activeOverlay.data.title }}
          <span v-if="activeOverlay.data.entityName" class="font-bold">
            {{ activeOverlay.data.entityName }}
          </span>
        </div>

        <div v-if="lockPinCode.locked">
          <div class="largeListItemHeader mb-10 text-center error mt-8">
            {{ $t('modals.tooManyAttempts') }}
          </div>
          <div class="text-center">
            {{ $t('modals.passwordLocked', { amount: displayedCountDown }) }}
            {{ $t('global.minutes') }}
          </div>
        </div>

        <div v-if="!lockPinCode.locked" class="inline-flex mb-10 ml-12">
          <div
            v-for="(item, index) in 4"
            :key="index"
            class="w-14 h-14 border border-charcoal border-b-2 rounded-full"
            :class="{
              'mr-3': index !== 4,
              'bg-charcoal': pin.length >= item,
              'bg-error': validPin === false,
            }"
            :style="{ opacity: 0.25 }"
          />
          <fa-icon
            class="text-3xl self-center"
            :icon="['fal', 'backspace']"
            @click="pin.pop()"
          />
        </div>

        <div
          v-if="!lockPinCode.locked"
          class="flex flex-wrap mb-10 justify-center w-1/3"
        >
          <div
            v-for="(number, index) in numbers"
            :key="index"
            class="w-20 h-20 shadow-lg rounded-full mb-3 text-5xl flex items-center justify-center text-white"
            :class="{ 'mr-3': ![3, 6, 9, 0].includes(number) }"
            :style="{ backgroundColor: colors[index] }"
            @click="numberPressed(number)"
          >
            {{ number }}
          </div>
        </div>
      </div>

      <div
        v-if="pinValidation.validPin"
        class="flex flex-col flex-1 items-center"
      >
        <div class="largeListItemHeader mb-10 pt-20 text-center success">
          <fa-icon :icon="['fa', 'check']" />
          {{ $t('global.completed') }}
        </div>

        <div class="content text-center mb-20">
          {{ activeOverlay.data.titleSuccess }}
          <span class="font-bold">{{ activeOverlay.data.entityName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
