import { apolloClient } from '../../utils/apollo';
import { departmentQuery } from '@graphql/queries/department.js';
import { departmentWeatherQuery } from '@graphql/queries/departmentWeather.js';
import { myArchiveScreenQuery } from '@graphql/queries/myArchiveScreen.js';
import store from '@store';
import { get, cloneDeep } from 'lodash';
import { getModuleData } from '@utils/modules';
import * as Sentry from '@sentry/vue';

const state = {
  loginKey: '',
  color: '',
  icon: '',
  id: null,
  hasValidExternalModule: null,
  institutionId: null,
  presentation: {
    description: '',
    image: '',
  },
  name: '',
  settings: {
    screenConfig: {
      gridSize: null,
      modules: [],
      footerConfig: [],
      timeout: null,
      slideshow: {
        duration: null,
      },
    },
    appConfig: {
      modules: [],
    },
  },
  weather: {},
  archive: {},
};

const mutations = {
  SET_HAS_VALID_EXTERNAL_MODULE(state, isExternalActivitiesValid) {
    state.hasValidExternalModule = isExternalActivitiesValid;
  },
  SET_DEPARTMENT(state, department) {
    Object.assign(state, department);
  },
  SET_WEATHER(state, weather) {
    state.weather = weather;
  },
  SET_MY_ARCHIVE(state, archive) {
    state.archive = archive;
  },
  SET_DEPARTMENT_ID(state, id) {
    state.id = id;
  },
};

const actions = {
  // Checks if the external activity module is valid.
  // Used by institution module and department, as this state depends on both.
  checkIfValidExternalModule({ state, commit }) {
    const hasExternalActivitiesModule =
      state.settings.screenConfig?.footerConfig?.some(
        (module) => module === 'EXTERNAL_ACTIVITIES'
      );

    // Check if an external connection is configured.
    const hasExternalConnections =
      store.getters['institution/externalConnections']?.length > 0;

    // The module is only valid if both are present.

    // If footer uses external activites without an appConnection setup log error.
    if (hasExternalActivitiesModule && !hasExternalConnections) {
      Sentry.captureException(
        new Error(
          'Footer config uses external activities without external connection set up',
          {
            institutionId: state?.institutionId,
          }
        )
      );
    }
    commit('SET_HAS_VALID_EXTERNAL_MODULE', hasExternalConnections && hasExternalActivitiesModule);
  },
  async getDepartment({ commit, dispatch }) {
    if (!state.id) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: departmentQuery,
        variables: {
          id: state.id,
        },
      })
      .then((response) => {
        const department = cloneDeep(get(response, 'data.department', []));
        const moduleData = getModuleData();

        const modules = department.settings.screenConfig.modules.map(
          (module) => {
            const rowValues = module.rowSpan.split('/');
            const colValues = module.colSpan.split('/');
            const name =
              moduleData[module.type].name || moduleData[module.type].component;

            return {
              type: module.type,
              name,
              colorId: moduleData[module.type].colorId,
              colorHex: moduleData[module.type].colorHex,
              icon: moduleData[module.type].icon,
              row: {
                start: rowValues[0],
                end: rowValues[1],
              },
              col: {
                start: colValues[0],
                end: colValues[1],
              },
              frHeight: rowValues[1] - rowValues[0],
              frWidth: colValues[1] - colValues[0],
            };
          }
        );

        department.settings.screenConfig.modules = modules;
        commit('SET_DEPARTMENT', department);
        dispatch('checkIfValidExternalModule');
      })
      .catch((error) => {
        console.log('Error getting department', error);
      });
  },
  async setDepartmentId({ commit }, id) {
    commit('SET_DEPARTMENT_ID', id);
  },
  async switchDepartment({ commit }, department) {
    commit('general/SET_LOADING', true, { root: true });

    if (!department) {
      sessionStorage.removeItem('temp_login_key');
      sessionStorage.removeItem('temp_departments');
    } else {
      sessionStorage.setItem('temp_login_key', department.loginKey);
    }

    window.localStorage.clear();
    window.location.href = '/';
  },
  async getWeather({ commit }) {
    const departmentId = store.getters['department/id'];

    await apolloClient
      .query({
        query: departmentWeatherQuery,
        variables: {
          id: departmentId,
        },
      })
      .then((response) => {
        commit('SET_WEATHER', JSON.parse(response.data.departmentWeather));
      })
      .catch((error) => {
        console.log('Error getting weather', error);
      });
  },
  async getArchive({ commit }, id) {
    await apolloClient
      .query({
        query: myArchiveScreenQuery,
        variables: {
          profile: {
            profileType: 'DEPARTMENT',
            profileId: id,
          },
        },
      })
      .then((response) => {
        commit('SET_MY_ARCHIVE', response.data.myArchiveScreen);
      })
      .catch((error) => {
        console.log('Error getting my archive', error);
      });
  },
};

const getters = {
  id: (state) => state.id,
  name: (state) => state.name,
  color: (state) => state.color,
  settings: (state) => state.settings,
  idleTimeout: (state) => state.settings.screenConfig.timeout,
  weather: (state) => state.weather,
  archive: (state) => state.archive,
  presentation: (state) => state.presentation,
  loginKey: (state) => state.loginKey,
  current: (state) => state,
  hasValidExternalModule: (state) => state.hasValidExternalModule,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
