import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMegaphone,
  faBus,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCalendar,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faTableCellsLarge,
  faTableCells,
  faGrid,
  faList,
  faHouseUser,
  faPhotoVideo,
  faUtensils,
  faRunning,
  faIdCard,
  faCommentAltLines,
  faCommentsAlt,
  faKey,
  faPhotoFilm,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faHome,
  faCheck,
  faLock,
  faLocationArrow,
  faCloudRain,
  faCloudSun,
  faClouds,
  faCloud,
  faSun,
  faWind,
  faSlash,
  faCloudBolt,
  faMoonStars,
} from '@fortawesome/pro-solid-svg-icons';

// SOLID

library.add(
  faMegaphone,
  faBus,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCalendar,
  faChevronDown,
  faChevronUp,
  faArrowLeft,
  faTableCellsLarge,
  faTableCells,
  faGrid,
  faList,
  faHouseUser,
  faPhotoVideo,
  faUtensils,
  faRunning,
  faIdCard,
  faCommentAltLines,
  faCommentsAlt,
  faKey,
  faPhotoFilm,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faHome,
  faCheck,
  faLock,
  faLocationArrow,
  faCloudRain,
  faCloudSun,
  faClouds,
  faCloud,
  faSun,
  faWind,
  faSlash,
  faCloudBolt,
  faMoonStars
);
