<template>
  <div
    class="card-basic flex flex-col items-center justify-center relative p-4"
    :class="backgroundColor"
  >
    <span
      class="content bold text-center"
      :style="{ color: active ? 'white' : moduleColorHex }"
    >
      {{ $t(title) }}
    </span>

    <img class="mt-6" v-if="imageUrl" :src="imageUrl" />

    <span
      v-if="active"
      class="active-icon rounded-full bg-success flex items-center justify-center border-2 border-white absolute"
    >
      <fa-icon class="text-white text-3xl" :icon="['fal', 'check']" />
    </span>
  </div>
</template>

<script>
import colors from '@utils/colors.js'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    moduleColor() {
      return this.$route.meta.color
    },
    moduleColorHex() {
      return colors[this.moduleColor]
    },
    backgroundColor() {
      return this.active ? `bg-${this.moduleColor}` : ''
    },
  },
}
</script>

<style lang="scss">
.card-basic {
  border-radius: 12px;
  width: 300px;
  height: 300px;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }

  .active-icon {
    width: 90px;
    height: 90px;
    right: 20px;
    bottom: 20px;
  }
}
</style>
