<script setup>
import { computed, ref } from 'vue';
import { VueperSlides } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import { useStore } from 'vuex';
import { useSwipe } from '@vueuse/core';

const store = useStore();
const slider = ref();
const swipingDirection = ref();
const emit = defineEmits(['slide', 'ready']);

// We need to override the touch in Vueperslides as our screens don't respond to normal touch / click events
const { direction, lengthX } = useSwipe(slider, {
  passive: true,
  onSwipe() {
    swipingDirection.value = direction.value;
  },
  onSwipeEnd() {
    if (swipingDirection.value === 'left' && lengthX.value >= 50) {
      slider.value.next();
    } else if (lengthX.value <= -50) {
      slider.value.previous();
    }
  },
});

const props = defineProps({
  slideshow: {
    type: Boolean,
    default: false,
  },
  slideCount: {
    type: Number,
    default: 0,
  },
  bullets: {
    type: Boolean,
    default: true,
  },
  infinite: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  customBullets: {
    type: Boolean,
    default: false,
  },
  customBulletTitles: {
    type: Array,
    default: () => [],
  },
});

const slideDuration = computed(() => {
  const settings = store.getters['department/settings'];

  // Use slideshow duration if slideshow prop is passed
  if (props.slideshow && settings?.slideshow?.duration) {
    return settings.slideshow.duration * 1000;
  }
  // Use carousel duration otherwise
  return settings.screenConfig.carouselDuration * 1000;
});

function onSlide(event) {
  emit('slide', event.currentSlide.index);
}

function onSliderReady() {
  if (slider.value && props.autoplay) {
    slider.value.doAutoplay();
  }
  emit('ready');
}
</script>

<template>
  <VueperSlides
    ref="slider"
    :duration="slideDuration"
    :arrows="false"
    :draggingDistance="20000"
    :transitionSpeed="1100"
    :touchable="true"
    :bullets="bullets"
    :infinite="infinite ? slideCount > 1 : false"
    :autoplay="autoplay"
    :pause-on-touch="false"
    :pause-on-hover="false"
    @slide="onSlide($event)"
    @ready="onSliderReady()"
    class="exclude-style no-shadow"
  >
    <slot></slot>

    <template
      v-if="customBullets"
      #bullets="{ bulletIndexes, goToSlide, currentSlide }"
      class="exclude-style"
    >
      <div
        class="z-20 flex items-center justify-between rounded-full bg-black-025 p-1 max-w-2xl mb-4"
      >
        <p
          v-for="(slideIndex, i) in bulletIndexes"
          :key="i"
          :class="{ active: currentSlide === slideIndex }"
          class="custom-bullet py-2 px-4 h300"
          @click="goToSlide(slideIndex)"
        >
          {{ customBulletTitles[slideIndex] }}
        </p>
      </div>
    </template>
  </VueperSlides>
</template>

<style>
.custom-bullet {
  font-size: 0.6rem;
}

.active {
  border-radius: 15px;
  background: white;
  color: black;
}

/* .vueperslides__bullet {
  height: 20px;
  width: 20px;
}

.vueperslides__bullet .default {
  background-color: white;
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 10px;
  height: 10px;
  opacity: 0.6;
}

.vueperslides__bullet--active .default {
  background-color: white;
  width: 16px;
  height: 16px;
  opacity: 1;
} */
</style>
