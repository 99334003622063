<script setup>
import { useStore } from 'vuex';
import { getModuleData } from '@utils/modules';
import { computed, watch, onUnmounted, onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';

const store = useStore();

const isLoading = ref(true);

const galleries = computed(() => store.getters['galleries/all']);
const categories = computed(() => store.getters['galleries/categories']);
const selectedCategory = computed(
  () => store.getters['galleries/selectedCategory']
);

const shouldShowSectionDivider = computed(
  () => selectedCategory.value !== 'AllCategoriesSelected'
);

watch(galleries, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    getCategories(newValue);
  }
});

onBeforeMount(async () => {
  await store.dispatch('galleries/getGalleries');
  getCategories(galleries.value);
  isLoading.value = false;
});

onUnmounted(() => {
  store.dispatch('galleries/setCategories', []);
});

const groupByKey = computed(() => {
  return !!categories.value.length ? 'category' : 'AllCategoriesSelected';
});

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().GALLERY;

function getCategories(galleries) {
  const categories = [
    ...new Set(
      galleries
        .filter((gallery) => gallery.category)
        .map((gallery) => gallery.category.title)
        .sort((a, b) => a.localeCompare(b))
    ),
  ];

  store.dispatch('galleries/setCategories', categories);
}
</script>

<template>
  <ModuleBaseView
    v-if="!isLoading"
    :moduleName="emptyKey"
    :moduleData="galleries"
    :colorHex="colorHex"
    :icon="icon"
    :emptyKey="emptyKey"
    :routeName="routeName"
    :entryRouterName="entryRouterName"
    :shouldShowMediaCount="true"
    :groupByKey="groupByKey"
    :filter="selectedCategory"
    :shouldShowSectionDivider="shouldShowSectionDivider"
  />
</template>
