<script setup>
import { useStore } from 'vuex';
import { getModuleData } from '@utils/modules';
import { computed, onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';

const store = useStore();

const isLoading = ref(true);

const moduleData = computed(() => store.getters['activities/external']);

const { colorHex, icon, emptyKey, routeName, entryRouterName, isExternal } =
  getModuleData().EXTERNAL_ACTIVITIES;

// Find the external module settings to know which asset to use
const externalConnectionIcon = computed(() => {
  const iconName =
    store.getters['institution/externalConnections']?.[0]?.icon ?? icon;

  return iconName;
});

onBeforeMount(async () => {
  await store.dispatch('activities/getActivities');
  isLoading.value = false;
});
</script>

<template>
  <div>
    <ModuleBaseView
      v-if="!isLoading"
      :moduleName="emptyKey"
      :moduleData="moduleData"
      :colorHex="colorHex"
      :icon="externalConnectionIcon"
      :emptyKey="emptyKey"
      :routeName="routeName"
      :entryRouterName="entryRouterName"
      :isExternal="isExternal"
    />
  </div>
</template>
