<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
    :duration="800"
  >
    <div
      class="media-preview w-full bg-charcoal-opacity z-30 fixed flex flex-col justify-center"
      :class="{ 'pt-48': mediaType !== 'documents' }"
    >
      <VideoSlideshow
        v-if="mediaType === 'videos'"
        :allowArchiveRemove="allowArchiveRemove"
        :videos="items"
        :selectedItemIndex="selectedItemIndex"
        @archive-add="archiveAdd"
        @archive-remove="archiveRemove"
        @close-preview="$emit('close-preview')"
      ></VideoSlideshow>

      <ImageSlideshow
        v-if="mediaType === 'pictures'"
        :pictures="items"
        :allowArchiveRemove="allowArchiveRemove"
        :selectedItemIndex="selectedItemIndex"
        @archive-add="archiveAdd"
        @archive-remove="archiveRemove"
        @close-preview="$emit('close-preview')"
      ></ImageSlideshow>

      <DocumentSlideshow
        v-if="mediaType === 'documents'"
        :documents="items"
        :allowArchiveRemove="allowArchiveRemove"
        :selectedItemIndex="selectedItemIndex"
        @close-preview="$emit('close-preview')"
        @archive-add="archiveAdd"
        @archive-remove="archiveRemove"
      >
      </DocumentSlideshow>

      <!-- TODO: Use when implementing Dreambroker ( useExternalMedia ) features -->
      <!-- <div v-if="mediaType === 'media'" class="h-full">
              <img v-if="item.__typename === 'GalleryPicture'" :src="item.source"
                class="px-10 object-cover w-full h-auto">
              <div v-else class="w-full h-full flex justify-center">
                <iframe v-if="mediaType === 'media' && currentSlide === index" class="w-full h-full"
                  :src="`${item.url}?autoplay=true`" allow="autoplay" />
              </div>
            </div>
          </slide>
        </custom-carousel> -->
    </div>
  </transition>
</template>

<script>
import CarouselControls from '@components/shared/CarouselControls.vue';
import Button from '@components/shared/Button.vue';
import { LazyVimeo } from 'vue-lazytube';
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import VideoSlideshow from '@components/shared/VideoSlideshow.vue';
import ImageSlideshow from '@components/shared/ImageSlideshow.vue';
import DocumentSlideshow from '@components/shared/DocumentSlideshow.vue';
import { VueperSlides, VueperSlide } from 'vueperslides';

export default {
  components: {
    CarouselControls,
    ButtonEl: Button,
    LazyVimeo,
    VueperSlide,
    VueperSlides,
    VideoSlideshow,
    ImageSlideshow,
    DocumentSlideshow,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    selectedItemIndex: {
      type: Number,
      default: 0,
    },
    mediaType: {
      type: String,
      default: '',
    },
    allowArchiveRemove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      opt: '',
      archiveCurrentResourceId: -1,
    };
  },
  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile',
      pinValidation: 'profiles/pinValidation',
      myArchive: 'profiles/myArchive',
      institutionSettings: 'institution/settings',
      departmentSettings: 'department/settings',
      selectedProfile: 'profiles/activeProfile',
    }),
    allowArchiveAdd() {
      return this.mediaType !== 'documents' && !this.useExternalMedia;
    },
    slideshowDuration() {
      return this.departmentSettings.slideshow.duration * 1000;
    },
    useExternalMedia() {
      return get(this.institutionSettings, 'useExternalMedia', false);
    },
  },
  watch: {
    pinValidation(pinData) {
      if (!pinData.validating) return;

      const archiveData = {
        resourceId: this.archiveCurrentResourceId,
        profile: {
          profileId: this.activeProfile.id,
          profileType: this.activeProfile.type,
          pin: pinData.pin.toString(),
        },
        opt: this.opt,
      };

      this.$store.dispatch('galleries/archiveAddScreen', archiveData);

      if (this.opt === 'out') {
        setTimeout(() => {
          this.$emit('close-preview');
        }, 500);
      }
    },
  },
  methods: {
    archiveAdd(data) {
      this.archiveCurrentResourceId = data.id;

      this.opt = 'in';

      const pinOverlayData = {
        title: this.$t(`media.addToMy.${data.mediaType}`),
        titleSuccess: this.$t(`media.addedToMy.${data.mediaType}`),
        opt: this.opt,
      };

      this.showOverlay(pinOverlayData);
    },
    async archiveRemove(data) {
      this.archiveCurrentResourceId = data.id;

      this.opt = 'out';
      const pinOverlayData = {
        title: this.$t(`media.removeFromMy.${data.mediaType}`),
        titleSuccess: this.$t(`media.removedFromMy.${data.mediaType}`),
        opt: this.opt,
      };
      await this.$store.dispatch(
        'profiles/setActiveProfile',
        this.selectedProfile
      );
      await this.$store.dispatch('general/setActiveOverlay', {
        name: 'enter-pin',
        data: pinOverlayData,
      });
    },
    showOverlay(pinOverlayData) {
      this.$store.dispatch('general/setActiveOverlay', {
        name: 'profile-select',
        data: pinOverlayData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.media-preview {
  transition: none;
  height: calc(100% - 280px);
  top: 0;
}
</style>
