import { createStore } from 'vuex';

import general from '@store/modules/general';
import activities from '@store/modules/activities';
import institution from '@store/modules/institution';
import profiles from '@store/modules/profiles';
import galleries from '@store/modules/galleries';
import news from '@store/modules/news';
import department from '@store/modules/department';
import meals from '@store/modules/meals';
import workSchedule from '@store/modules/workSchedule';
import meetings from '@store/modules/meetings';
import bookingResources from '@store/modules/bookingResources';
import ourHome from '@store/modules/ourHome';
import journeyPlanner from '@store/modules/journeyPlanner';
import idleHandler from '@store/modules/idleHandler';

export default createStore({
  modules: {
    general,
    activities,
    institution,
    profiles,
    galleries,
    news,
    department,
    meals,
    workSchedule,
    meetings,
    bookingResources,
    ourHome,
    journeyPlanner,
    idleHandler,
  },
});
