<template>
  <div class="flex flex-col" :style="{ height: '1875px' }">
    <div class="content flex flex-col h-full">
      <tab-group
        v-if="!employeeOnly"
        :selected-profile-type="profileType"
        @tab-selected="profileType = $event"
      />
      <div
        class="grid grid-cols-3 gap-y-10 pt-12 pb-96 px-16 overflow-scroll max-h-full"
        :class="{
          'grid-cols-3 gap-x-4': size === 'large',
          'grid-cols-5 gap-x-0': size !== 'large',
        }"
      >
        <div
          v-for="(profile, index) in shownProfiles"
          :key="index"
          class="profile flex flex-col items-center"
          :class="{ 'h-36': size !== 'large', 'h-60': size === 'large' }"
          @click="selectProfile(profile)"
        >
          <img
            class="profileImage rounded-full shadow-lg object-cover border-8 mb-4"
            :src="profile.displayImage"
            :class="[
              size,
              { 'border-success': isSelected(profile) },
              { 'border-white': !isSelected(profile) },
            ]"
          />
          <p
            class="text-2xl font-bold text-center"
            :class="{ 'text-success font-bold': isSelected(profile) }"
          >
            {{ profile.displayName }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="sticky flex flex-col items-center justify-end pt-4 pb-6 w-96 z-20 self-center"
      :class="[selectBottom]"
    >
      <select-el
        v-if="availableDepartments.length > 1"
        icon="house-user"
        :options="availableDepartments"
        :selected-option="selectedDepartment"
        @option-selected="departmentSelected"
      />

      <button-el
        v-if="canSave"
        text="booking.confirmBooking"
        icon="check"
        :is-save="true"
        :shadow="true"
        class="w-full self-center mb-32"
        background-color="success"
        @click="save()"
      />

      <button-el
        v-if="canClose"
        text="global.close"
        icon="times"
        :shadow="true"
        background-color="#fff"
        text-color="#000"
        class="w-auto inline-block self-center mb-4"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@components/shared/Button.vue';
import TabGroup from '@components/shared/TabGroup.vue';
import SelectEl from '@components/shared/SelectEl.vue';

export default {
  components: {
    SelectEl,
    ButtonEl: Button,
    TabGroup,
  },
  props: {
    canClose: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    employeeOnly: {
      type: Boolean,
      default: false,
    },
    selectedProfileType: {
      type: String,
      default: 'RESIDENT',
    },
    selectBottom: {
      type: String,
      default: 'bottom-12',
    },
    sharedDepartments: {
      type: Array,
      default: () => [],
    },
    entity: {
      type: String,
      default: 'other',
    },
    participants: {
      type: Array,
      default: () => [],
    },
    activeOptInOrOut: {
      type: String, // 'in' || 'out'
      default: null,
    },
  },
  data() {
    return {
      showResidents: true,
      selectedProfiles: [],
      selectedDepartment: {},
      profileType: '',
    };
  },
  computed: {
    ...mapGetters({
      departments: 'institution/departments',
      institutionLocale: 'institution/locale',
      profiles: 'profiles/all',
      departmentId: 'department/id',
    }),
    shownProfiles() {
      const filteredProfiles = this.profiles
        .filter((profile) =>
          this.getDepartmentIds(profile).includes(
            parseInt(this.selectedDepartment.id)
          )
        )
        .filter((profile) => profile.type === this.profileType)
        .sort((a, b) =>
          a.firstName.localeCompare(b.firstName, this.institutionLocale)
        );

      // If no participants just return found profiles.
      if (!this.participants.length) {
        return filteredProfiles;
      }

      // If opting out, select only profiles from the department that are participating.
      if (this.activeOptInOrOut === 'out') {
        const participatingDepartmentProfiles = filteredProfiles.filter(
          (profile) =>
            this.participants.some(
              (participant) => participant.id === profile.id
            )
        );
        return participatingDepartmentProfiles;
      }

      return filteredProfiles.filter((profile) =>
        this.participants.some((participant) => participant.id === profile.id)
      );
    },
    size() {
      return this.shownProfiles.length > 25 ? 'small' : 'large';
    },
    availableDepartments() {
      return this.sharedDepartments.reduce((accumulator, current) => {
        this.departments.forEach((department) => {
          if (department.id === current.id) {
            accumulator.push(current);
          }
        });

        return accumulator;
      }, []);
    },
  },
  mounted() {
    this.profileType = this.selectedProfileType;

    this.selectedDepartment = this.departments.find(
      (department) => department.id == this.departmentId
    );
  },
  methods: {
    getDepartmentIds(profile) {
      return profile.departments.map((department) => parseInt(department.id));
    },
    selectProfile(profile) {
      const profileIndex = this.selectedProfiles.indexOf(profile);

      if (profileIndex > -1) {
        this.selectedProfiles.splice(profileIndex, 1);
      } else {
        this.selectedProfiles.push(profile);
      }

      if (!this.multiselect) {
        this.$emit('profile-selected', profile);
      }
    },
    isSelected(profile) {
      return this.selectedProfiles.indexOf(profile) > -1;
    },
    save() {
      this.$emit('save', this.selectedProfiles);
    },
    departmentSelected(department) {
      this.$emit('department-selected', department);
      this.selectedDepartment = department;
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  &.inline {
    display: flex;
    align-items: center;
    margin-left: 40px;

    .content {
      margin-left: 20px;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  .profileImage {
    &.small {
      width: 96px;
      height: 96px;
    }

    &.large {
      width: 190px;
      height: 190px;
    }
  }
}
.select-el {
  bottom: 250px;
}
</style>
