import gql from 'graphql-tag';

export const activityEntryQuery = gql`
  query activityEntry($id: ID!) {
    activityEntry(id: $id) {
      ... on SingleActivity {
        id
        title
        institution {
          name
          color
          id
        }
        coverImage {
          id
          source
        }
        pictograms {
          id
          source
          description
        }
        pictures {
          id
          source
        }
        videos {
          id
          provider
          url
          description
          thumbnail
        }
        documents {
          id
          url
        }
        departments {
          color
          id
          name
        }
        startDate
        endDate
        signupStart
        signupEnd
        maxSignups
        signupPossible
        description
        location
        club {
          id
          logo {
            id
            source
          }
          name
          color
        }
        price {
          price
          currency
        }
        responsible {
          ... on Employee {
            type
            id
            displayImage
            showImage
            displayName
            departments {
              name
              color
            }
          }
          ... on Resident {
            type
            id
            displayImage
            showImage
            displayName
            departments {
              name
              color
            }
          }
        }
        participants {
          ... on Employee {
            type
            id
            displayImage
            showImage
            displayName
            departments {
              name
              color
            }
          }
          ... on Resident {
            type
            id
            displayImage
            showImage
            displayName
            departments {
              name
              color
            }
          }
        }
        participating
        cancelled
        groups {
          id
          color
          participants {
            ... on Employee {
              id
            }
            ... on Resident {
              id
            }
          }
          description
        }
      }
      ... on RepeatingActivity {
        repeat {
          description
          rrule
          rruleText
        }
        id
        title
        institution {
          name
          color
          id
        }
        coverImage {
          id
          source
        }
        pictograms {
          id
          source
          description
        }
        pictures {
          id
          source
        }
        videos {
          id
          provider
          url
          description
          thumbnail
        }
        documents {
          id
          url
        }
        departments {
          color
          id
          name
        }
        startDate
        endDate
        signupStart
        signupEnd
        maxSignups
        signupPossible
        description
        location
        club {
          id
          logo {
            id
            source
          }
          name
          color
        }
        price {
          price
          currency
        }
        responsible {
          ... on Employee {
            type
            id
            displayImage
            showImage
            displayName
            departments {
              name
              color
            }
          }
          ... on Resident {
            type
            id
            displayImage
            showImage
            displayName
            departments {
              name
              color
            }
          }
        }
        participants {
          ... on Employee {
            type
            id
            displayImage
            showImage
            displayName
            departments {
              name
              color
            }
          }
          ... on Resident {
            type
            id
            displayImage
            showImage
            displayName
            departments {
              name
              color
            }
          }
        }
        participating
        cancelled
        groups {
          id
          color
          participants {
            ... on Employee {
              id
            }
            ... on Resident {
              id
            }
          }
          description
        }
      }
    }
  }
`;
