<script setup>
import { useStore } from 'vuex';
import { ref } from 'vue';
import { default as ButtonEl } from '@components/shared/Button.vue';
import KeyboardSimple from '@components/shared/KeyboardSimple.vue';

const store = useStore();
const emit = defineEmits(['close-overlay']);

const description = ref('');

async function next() {
  await store.dispatch('bookingResources/setNewBooking', {
    title: description.value,
  });

  await store.dispatch('general/setNextActiveOverlay', {
    name: 'new-booking-participants',
  });

  emit('close-overlay');
}
</script>

<template>
  <div>
    <div
      class="flex justify-between self-center px-20 pb-48 -mt-12 rounded-t-3xl"
    >
      <div class="largeListItemHeader">
        {{ $t('booking.addDescription') }}
      </div>
    </div>

    <div class="px-20 pb-12">
      <keyboard-simple @onChange="description = $event" />
    </div>

    <div class="w-full flex justify-center">
      <button-el
        :text="description.length ? 'modals.next' : 'modals.skip'"
        icon="arrow-right"
        background-color="success"
        text-color="white"
        class="mr-5 mt-8 shadow-xsm"
        @click="next"
      />
    </div>
  </div>
</template>
