<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import 'vueperslides/dist/vueperslides.css';
import { isToday, parseISO, isAfter } from 'date-fns';
import { getModuleData } from '@utils/modules';
import BaseWidget from '@components/widgets/BaseWidget.vue';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();

const navigateToModule = (item) => {
  const path = item ? `/${routeName}/${item.id}` : `/${routeName}`;
  router.push(path);
};

const { colorHex, icon, name, emptyKey, routeName } = getModuleData().MEAL_PLAN;

const meals = computed(() => {
  const allMeals = store.getters['meals/all'];
  const currentTime = new Date();

  const mealsToday = allMeals.filter((meal) => {
    const mealStart = parseISO(meal.startDate);
    const mealEnd = parseISO(meal.endDate);

    return isToday(mealStart) && isAfter(mealEnd, currentTime);
  });

  return mealsToday;
});

const isWideWidget = computed(() => {
  const { frWidth } = store.getters[
    'department/settings'
  ]?.screenConfig?.modules?.find((module) => module.name === name);
  return frWidth >= 2;
});
</script>

<template>
  <BaseWidget
    @slide-pressed="navigateToModule($event)"
    :routeName="routeName"
    :widgetData="meals"
    :backgroundColor="colorHex"
    :icon="icon"
    iconType="fas"
    :emptyKey="emptyKey"
    :isWideWidget="isWideWidget"
  />
</template>
