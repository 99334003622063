<script setup>
import { getStartDay, formatTime } from '@utils/helpers.js';
import { capitalize } from 'lodash';
import Status from '@components/shared/infoLabels/Status.vue';
import EditWorkScheduleControls from '@components/shared/EditWorkScheduleControls.vue';
import TimeBlock from '@components/shared/blocks/TimeBlock.vue';
import SimpleTimeBlock from '@components/shared/blocks/SimpleTimeBlock.vue';
import { isAfter } from 'date-fns';
import { isWithinInterval } from '@/utils/date-fns';
import constants from '@utils/constants';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import i18n from '@i18n';
import { toDate } from 'date-fns-tz';

const store = useStore();
const route = useRoute();
const emit = defineEmits(['close-overlay']);

const today = ref(new Date());

const isNormalWS = computed(() => {
  return route.name === 'WORK_SCHEDULE';
});

const institutionSettings = computed(() => {
  return store.getters['institution/settings'];
});

const departmentSettings = computed(() => {
  return store.getters['department/settings'];
});

const shiftStart = computed(() => {
  return toDate(shift.value.start, { timeZone: constants.timezone });
});

const shiftEnd = computed(() => {
  return toDate(shift.value.end, { timeZone: constants.timezone });
});

const unavailabilityStart = computed(() => {
  return toDate(shift.value.unavailable[0].start, {
    timeZone: constants.timezone,
  });
});

const unavailabilityEnd = computed(() => {
  return toDate(shift.value.unavailable[0].end, {
    timeZone: constants.timezone,
  });
});

const moduleColor = computed(() => {
  return route.meta?.color;
});

const shift = computed(() => {
  return store.getters['workSchedule/selectedShift'];
});

const unavailableDuration = computed(() => {
  return `${formatTime(shift.value.unavailable[0].start)} - ${formatTime(shift.value.unavailable[0].end)}`;
});

const unavailableNow = computed(() => {
  if (!shift.value.unavailable) return false;

  if (
    isAfter(new Date(), unavailabilityStart.value) &&
    isAfter(unavailabilityEnd.value, new Date())
  )
    return true;
  return false;
});

const unavailabilityDescription = computed(() => {
  return shift.value.unavailable[0].description;
});

const shiftStarted = computed(() => {
  return (
    isAfter(today.value, shiftStart.value) &&
    isAfter(shiftEnd.value, today.value)
  );
});

const shiftOver = computed(() => {
  return isAfter(new Date(), shiftEnd.value);
});

const canEditWorkSchedule = computed(() => {
  return !institutionSettings.value.vismaGatWs;
});

const startDay = computed(() => {
  return capitalize(i18n.global.t(getStartDay(shift.value.start, true)));
});

const simpleTime = computed(() => {
  const settings = departmentSettings.value.simpleSchedulePlan;

  const iconMap = {
    morgen: 'sunrise',
    eftermiddag: 'sun',
    aften: 'sunset',
    nat: 'moon-stars',
  };

  const interval = settings.find((setting) => {
    return isWithinInterval(setting.starttime, setting.endtime);
  });

  return {
    ...interval,
    icon: iconMap[interval.icon],
  };
});

function getProfileImage(employee) {
  const placeholder = '/img/user-placeholder.png';

  if (!employee?.showImage || !employee?.displayImage?.length)
    return placeholder;

  return institutionSettings.value.vismaGatWs
    ? `data:image/jpg;base64,${employee.displayImage}`
    : employee.displayImage;
}

function closeOverlay() {
  emit('close-overlay');
}
</script>

<template>
  <div class="h-full z-50">
    <div class="h-full px-16 pt-5 border-t">
      <div
        class="-mt-96 absolute m-auto w-max right-0 left-0 flex flex-col items-center mb-16"
      >
        <div class="bg-white rounded-full overflow-hidden">
          <img
            :src="getProfileImage(shift.employee)"
            alt="Employee"
            class="w-60 h-60 border-8 border-white shadow-m object-cover rounded-full"
            :class="[{ 'filter grayscale': shiftOver || shift.cancelled }]"
          />
        </div>
        <div
          v-if="shift.nightShift"
          class="bg-charcoal z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom"
        >
          <fa-icon class="text-white text-xl" :icon="['fas', 'moon-stars']" />
        </div>
        <div
          v-else-if="shift.cancelled"
          class="bg-warning z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom"
        >
          <fa-icon class="text-white text-3xl" :icon="['far', 'times']" />
        </div>
        <div
          v-else-if="unavailableNow"
          class="bg-orange z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom"
        />

        <div
          v-else-if="shiftStarted"
          class="z-10 absolute w-16 h-16 rounded-full flex items-center justify-center mt-44 ml-48 border-4 border-white shadow-bottom"
          :class="`bg-${moduleColor}`"
        />

        <div class="largeListItemHeader py-4">
          {{ shift?.employee?.displayName }}
        </div>

        <div
          v-if="shift.cancelled"
          class="bg-warning rounded-lg py-4 px-6 mt-1 flex items-center justify-center"
        >
          <p class="labelSmall">{{ $t('scheduleShift.notComing') }}</p>
        </div>
      </div>

      <div class="px-12">
        <div v-if="shift.nightShift" class="flex mb-12 h-16">
          <fa-icon class="text-3xl mr-12" :icon="['fal', 'moon-stars']" />
          <p class="h700">
            {{ $t('global.nightShift') }}
          </p>
        </div>

        <div class="flex mb-12 h-16">
          <fa-icon class="text-3xl mr-12 w-8" :icon="['fal', 'calendar']" />
          <div>
            <p class="h700">
              {{
                shift.cancelled
                  ? $t('scheduleShift.notComing')
                  : $t('employee.working')
              }}
            </p>
            <p class="b500">{{ startDay }}</p>
          </div>
        </div>

        <div class="flex items-center justify-between w-full mb-12 h-16">
          <TimeBlock v-if="isNormalWS" :start="shift.start" :end="shift.end" />

          <SimpleTimeBlock
            :icon="simpleTime.icon"
            :text="simpleTime.name"
            v-else
          >
          </SimpleTimeBlock>

          <status
            v-if="shiftStarted"
            :color="moduleColor"
            icon="clock"
            :shadow="false"
          />
        </div>

        <div
          v-if="shift.unavailable"
          class="flex items-center justify-between w-full mb-12 h-16"
        >
          <div class="flex">
            <fa-icon class="text-3xl mr-12 w-8" :icon="['fal', 'ban']" />
            <div class="flex flex-col">
              <p class="h700">{{ $t('global.busy') }}</p>

              <p class="b500">
                <span>{{ unavailableDuration }}</span>
                <span v-if="unavailabilityDescription">
                  - {{ unavailabilityDescription }}</span
                >
              </p>
            </div>
          </div>

          <status
            v-if="unavailableNow"
            color="orange"
            icon="clock"
            :shadow="false"
          />
        </div>

        <edit-work-schedule-controls
          v-if="canEditWorkSchedule"
          :shift="shift"
          @close-overlay="closeOverlay"
        />
        <!-- TODO: Add back in when we have the profile view -->
        <!-- <div class="flex items-center justify-between w-full mb-12 h-16">
          <div class="flex">
            <fa-icon class="text-3xl mr-12 w-8" :icon="['fal', 'id-badge']"/>
            <p class="font-semibold" :class="`text-${moduleColor}`">{{ $t('global.showProfile') }}</p>
          </div>
          <fa-icon class="text-6xl" :icon="['fal', 'angle-right']"/>
        </div>  -->
      </div>
    </div>
  </div>
</template>
