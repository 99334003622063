<template>
  <div class="startPage">
    <div
      v-if="widgets.length"
      class="startPageGrid grid gap-6 p-6 h-full bg-neutral-100 pb-10"
    >
      <div
        v-for="(widget, index) in widgets"
        :key="index"
        :class="[
          `grid-${settings.screenConfig.gridSize}`,
          { hasSelector: hasDepartmentSelector(widget.name) },
          `frWidth${widget.frWidth}`,
        ]"
        class="h-full relative"
        :style="getGridStyles(widget)"
      >
        <component
          :is="widgetData[widget.type].component"
          :moduleName="widgetData[widget.type].emptyKey"
          :class="widgetData[widget.type].emptyKey"
          :widget="widgetData[widget.type]"
          :width="widget.frWidth"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import Slideshow from '@components/widgets/Slideshow.vue';
import JourneyPlannerWidget from '@components/widgets/JourneyPlannerWidget.vue';
import WeatherWidget from '@components/widgets/WeatherWidget.vue';
import WorkScheduleWidget from '@components/widgets/WorkScheduleWidget.vue';
import { getModuleData } from '@utils/modules';
import ActivityWidget from '@components/widgets/ActivityWidget.vue';
import ActivityWidgetExternal from '@components/widgets/ActivityWidgetExternal.vue';
import BookingsWidget from '@components/widgets/BookingsWidget.vue';
import MealPlanWidget from '@components/widgets/MealPlanWidget.vue';
import MeetingsWidget from '@components/widgets/MeetingsWidget.vue';
import NewsWidget from '@components/widgets/NewsWidget.vue';
import GalleryWidget from '@components/widgets/GalleryWidget.vue';

export default {
  components: {
    GalleryWidget,
    NewsWidget,
    MeetingsWidget,
    MealPlanWidget,
    BookingsWidget,
    ActivityWidgetExternal,
    ActivityWidget,
    Slideshow,
    JourneyPlannerWidget,
    WeatherWidget,
    WorkScheduleWidget,
  },
  data() {
    return {
      standardWidgetIds: [3, 4, 5, 6, 7, 10],
    };
  },
  computed: {
    ...mapGetters({
      settings: 'department/settings',
    }),
    widgets() {
      return get(this, 'settings.screenConfig.modules', []);
    },
    widgetData() {
      return getModuleData();
    },
  },
  methods: {
    getGridStyles(widget) {
      return {
        'grid-column': widget.col.start,
        'grid-column-end': widget.col.end,
        'grid-row-start': widget.row.start,
        'grid-row-end': widget.row.end,
      };
    },
    isStandardWidget(widget) {
      return this.standardWidgetIds.indexOf(widget.id) > -1;
    },
    hasDepartmentSelector(widget) {
      return ['meals', 'workShedule'].includes(widget);
    },
  },
};
</script>

<style lang="scss">
.startPage {
  .startPageGrid {
    height: 100%;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-rows: minmax(0, 1fr);
    grid-auto-flow: column;

    .hasSelector {
      .departmentSelector {
        filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.25));
        right: 24px;
      }

      &.frWidth1 {
        .VueCarousel-pagination {
          width: 60%;
        }
      }

      &.frWidth2 {
        .departmentSelector {
          width: 42%;
        }

        .VueCarousel-pagination {
          width: 45%;
        }

        .VueCarousel-dot-container {
          justify-content: flex-end !important;
        }
      }
    }
  }
}
</style>
