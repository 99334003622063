<template>
  <div class="animate transition-all">
    <TransitionGroup
      v-if="timeSlots.length"
      name="list"
      tag="ul"
    >
      <li
        v-for="( slot, index ) in slots"
        :key="index"
        class="relative border border-sm rounded rounded-md h-20 mb-4 flex items-center justify-center"
        :style="getStyles(slot)"
        @click="selectTime(slot, index)"
      >
        <fa-icon
          v-if="!slot.available && type !== 'end'"
          :icon="['fa', 'slash']"
          class="text-error transform rotate-90 absolute text-4xl"
        />
        <p class="smallListItemHeader">
          {{ slot.time }}
        </p>
      </li>
    </TransitionGroup>
  </div>
</template>

<script>
import { hexToRgbA, stringToMinutes } from '@utils/helpers';
import { isToday } from 'date-fns';
import { cloneDeep } from 'lodash';
import colors from '@utils/colors';

export default {
  props: {
    selectedDate: {
      type: String,
      'default': ''
    },
    startTime: {
      type: String,
      'default': '00:00'
    },
    endTime: {
      type: String,
      'default': '23:45'
    },
    type: {
      type: String,
      'default': 'start'
    },
    color: {
      type: String,
      'default': ''
    },
    timeSlots: {
      type: Array,
      'default': (() => [])
    }
  },
  data() {
    return {
      slots: [],
      timeSelected: false
    }
  },
  computed: {
    colorLight() {
      return hexToRgbA(this.color, 0.05);
    },
    today() {
      return new Date();
    },
    inactiveColor() {
      return hexToRgbA(colors.charcoal, 0.25);
    },
    allSlots() {
      const sameDay = isToday(new Date(this.selectedDate), new Date());
      const startInMinutes = stringToMinutes(this.startTime);
      const endInMinutes = stringToMinutes(this.endTime);

      return cloneDeep(this.timeSlots).filter(slot => {
        const slotInMinutes = stringToMinutes(slot.time);
        if (endInMinutes < slotInMinutes) return false;
        if (slotInMinutes < startInMinutes) return false;
        return sameDay ? slotInMinutes >= startInMinutes : true;
      });
    }
  },
  watch: {
    selectedDate() {
      this.slots = cloneDeep(this.allSlots);
    },
    timeSlots(newVal) {
      if (this.type === 'end') {
        this.slots = newVal
    }
  }
  },
  mounted() {
    this.slots = cloneDeep(this.allSlots);
  },
  methods: {
    selectTime(slot, index) {
      if (!slot.available && this.type === 'start') return;

      this.timeSelected = true;

      if (slot.selected) {
        this.slots = cloneDeep(this.allSlots);
        this.timeSelected = false;
        this.$emit('deselect');
        return;
      }
      
      this.slots = this.slots.splice(index, 1);
      slot.selected = true;

      document.querySelector('.backdrop').scrollTop = 0;

      this.$emit('time-selected', slot.time);
    },
    getStyles(slot) {
      if (slot.selected) {
        return {
          'background-color': this.color,
          'border-color': this.color,
          'color': '#fff'
        }
      }
      if (slot.available || this.type === 'end') {
        return {
          'background-color': this.colorLight,
          'border-color': this.color,
          'color': this.color
        }
      }
      return {
        'background-color': '#fff',
        'border-color': this.inactiveColor,
        'color': this.inactiveColor
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }
  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
</style>