<script setup>
import { useStore } from 'vuex';
import ProfilesList from '@components/shared/ProfilesList.vue';
import { computed, onMounted, getCurrentInstance } from 'vue';

const store = useStore();
const emit = defineEmits(['close-overlay']);
// TODO: Once we have App.vue migrated, we can use inject / provide instead from there
// https://stackoverflow.com/questions/68848942/how-to-call-a-custom-global-function-using-composition-api-vue3
const app = getCurrentInstance();
const constants = app.appContext.config.globalProperties.$constants;

onMounted(() => {
  document.querySelector('.overlay').scrollTop = 300;
  store.dispatch('profiles/setPinValidation', {});
});

const activeOverlay = computed(() => {
  return store.getters['general/activeOverlay'];
});

const employeeOnly = computed(() => {
  return activeOverlay.value.data.employeeOnly;
});

const selectedProfileType = computed(() => {
  return activeOverlay.value.data.selectedProfileType || 'RESIDENT';
});

const activeOptInOrOut = computed(() => {
  return activeOverlay.value.data.opt;
});

const entity = computed(() => {
  return activeOverlay.value.data.entity;
});

function close() {
  emit('close-overlay', true);

  setTimeout(() => {
    store.dispatch('general/resetOverlays', {});
  }, constants.animationDuration);
}

async function profileSelected(profile) {
  await store.dispatch('profiles/setActiveProfile', profile);
  await store.dispatch('general/setNextActiveOverlay', {
    name: 'enter-pin',
    data: activeOverlay.value.data,
  });

  emit('close-overlay');
}
</script>

<template>
  <div class="profile-select h-full bg-white overflow-y-scroll">
    <div class="content h-full flex flex-col">
      <div class="mb-12 mx-20">
        {{ $t('profileSelect.description') }}
      </div>

      <profiles-list
        class="h-full mb-48"
        :can-close="true"
        :employee-only="employeeOnly"
        :selected-profile-type="selectedProfileType"
        :shared-departments="activeOverlay.data.sharedDepartments"
        :active-opt-in-or-out="activeOptInOrOut"
        :entity="entity"
        select-bottom="bottom-96"
        :participants="activeOverlay.data.participants"
        @profile-selected="profileSelected"
        @close="close"
      />
    </div>
  </div>
</template>
