<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import CustomCarousel from '../shared/CustomCarousel.vue';
import 'vueperslides/dist/vueperslides.css';
import { VueperSlide } from 'vueperslides';
import { useRouter } from 'vue-router';
import colors from '@utils/colors.js';
import WidgetEmptyCard from '@components/cards/widgetEmptyCard/WidgetEmptyCard.vue';

const router = useRouter();
const store = useStore();
const slideIndex = ref(0);
const slideStyles = ref([]);

const emit = defineEmits(['slide', 'slide-pressed']);

const props = defineProps({
  widget: {
    type: Object,
    default: () => {},
  },
  width: {
    type: Number,
    default: 1,
  },
});

// TODO: Remember to use modules.js when implementing the slideshow.
// https://proreact.atlassian.net/browse/IBG-6300
function slideClicked(picture) {
  const path = picture ? `/galleries/${picture.galleryId}` : `/galleries`;
  router.push(path);
}

const slideCount = computed(() => {
  const picture = pictures.value[slideIndex.value];
  return picture?.count && picture?.index !== undefined
    ? `${picture.index + 1} / ${picture.count}`
    : '';
});

const allGalleries = computed(() => {
  return store.getters['galleries/all'];
});

const backgroundColor = computed(() => {
  return colors.GALLERY;
});

const pictures = computed(() => {
  return allGalleries.value
    .filter((gallery) => gallery.showOnFrontpage)
    .slice(0, props.width * 5)
    .map((gallery) => {
      return gallery.pictures.map((picture, index) => {
        return {
          source: picture.source,
          galleryTitle: gallery.title,
          galleryId: gallery.id,
          index,
          count: gallery.pictures.length,
        };
      });
    })
    .flat();
});

function onSlide(index) {
  slideIndex.value = index;
}

function getBackgroundStyles(picture, index) {
  const nextSlideIndex = slideIndex.value + 1;
  const previousSlideIndex =
    slideIndex.value === 0 ? pictures.value.length - 1 : slideIndex.value - 1;

  // If getting styles for current slide, previous or next index
  if ([previousSlideIndex, slideIndex.value, nextSlideIndex].includes(index)) {
    slideStyles.value[index] = `
          backgroundImage: url(${picture.source});
          backgroundPosition: center;
          backgroundRepeat: no-repeat;
          backgroundSize: cover;
      `;
    return slideStyles.value[index];
  }

  return '';
}
</script>

<template>
  <div v-if="pictures?.length > 0" class="relative">
    <CustomCarousel
      :gap="3"
      :slideshow="true"
      :slide-count="pictures.length"
      :bullets="false"
      @slide-clicked="slideClicked($event)"
      @slide="onSlide($event)"
    >
      <VueperSlide
        v-for="(picture, index) in pictures"
        :key="index"
        @click="slideClicked(picture)"
        :style="slideStyles[index] || getBackgroundStyles(picture, index)"
        class="rounded-xl overflow-hidden"
      >
        <template #content>
          <div
            class="w-full h-full rounded-2xl text-white flex items-center justify-end flex-col text-center px-4 pb-12"
          >
            <div
              class="flex flex-row justify-center items-center py-2 px-4 rounded-3xl max-w-full"
              :style="{ backgroundColor }"
            >
              <fa-icon
                class="h700 text-white pr-3"
                :icon="['fas', `${widget.icon}`]"
              />
              <p class="h700 truncate">
                {{ picture.galleryTitle }}
              </p>
            </div>
          </div>
        </template>
      </VueperSlide>
    </CustomCarousel>
    <div
      class="absolute h600 z-20 ring-3 ring-neutral-200 shadow-mini text-white flex items-center justify-center rounded-full px-2 py-1 min-h-14 min-w-14 top-4 left-4"
      :style="{ backgroundColor }"
    >
      {{ slideCount }}
    </div>
  </div>

  <WidgetEmptyCard
    v-else
    @click="slideClicked()"
    emptyKey="SLIDESHOW"
    icon="photo-video"
    :backgroundColor="colors.GALLERY"
  />
</template>
