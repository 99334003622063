<script setup>
import ProfilesList from '@components/shared/ProfilesList.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const emit = defineEmits(['close-overlay']);

const data = computed(() => store.getters['general/activeOverlay'].data);
const departments = computed(() => store.getters['institution/departments']);

function profileSelected(profile) {
  store.dispatch('general/setNextActiveOverlay', {
    name: 'replace-shift-confirmation',
    data: {
      selectedProfile: profile,
      selectedShift: data.value.selectedShift,
      action: data.value.action,
    },
  });
  emit('close-overlay');
}
</script>

<template>
  <div>
    <div class="content w-full mb-12 mx-20">
      {{ $t('scheduleShift.replaceShiftChooseText') }}
    </div>

    <profiles-list
      :can-save="false"
      :multiselect="false"
      :employee-only="true"
      :shared-departments="departments"
      selected-profile-type="EMPLOYEE"
      select-bottom="bottom-48"
      @profile-selected="profileSelected"
    />
  </div>
</template>
