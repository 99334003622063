<template>
  <router-link to="/"
      active-class="active"
      class="bg-transparent"
    >
    <img
      src="/svgs/ibg_logo.svg"
      class="p-2 border-2 border-neutral-400 rounded-full bg-white shadow-lg"
      style="height: 98px; width: 98px;"
    />
  </router-link>
</template>
