<template>
  <div class="h-28 flex">
    <div
      v-for="(time, index) in times"
      :key="index"
    >
      <div
        class="column text-3xl h-full flex items-center"
      >
        <p class="w-12 text-center font-normal">
          {{ time }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    times: {
      type: Array,
      'default': () => []
    }
  }
}
</script>

<style>
.column {
  width: 90px;
}
</style>