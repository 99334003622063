<template>
  <div class="flex flex-row items-center justify-between w-full px-32">
    <button-el
      class="content bold"
      text="global.play"
      icon="play"
      backgroundColor="success"
      @click="$emit('playVideo')"
    />

    <button-el
      class="content bold"
      text="global.pause"
      icon="pause"
      backgroundColor="error"
      @click="$emit('pauseVideo')"
    />

    <button-el
      class="content bold"
      text="global.restart"
      icon="undo-alt"
      backgroundColor="warning"
      @click="$emit('resetView')"
    />
  </div>
</template>

<script>
import Button from '@components/shared/Button.vue';

export default {
  components: {
    ButtonEl: Button,
  },
  props: {},
  computed: {},
  methods: {},
};
</script>
