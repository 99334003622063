<script setup>
import { computed, onMounted, ref } from 'vue';
import { getYear } from 'date-fns';
import { useStore } from 'vuex';
import { getModuleData } from '@utils/modules';
import BaseContentCard from '@components/cards/baseContentCard/BaseContentCard.vue';
import WidgetEmptyCard from '@components/cards/widgetEmptyCard/WidgetEmptyCard.vue';

const store = useStore();

const filterOptions = ref([
  {
    text: 'global.now',
    value: 0,
  },
  {
    text: 'journeyPlanner.30Minutes',
    value: 30,
  },
  {
    text: 'journeyPlanner.1Hour',
    value: 60,
  },
  {
    text: 'journeyPlanner.2Hours',
    value: 120,
  },
]);

const props = defineProps({
  widget: {
    type: Object,
    default: () => {},
  },
  moduleName: {
    type: String,
    required: true,
  },
});

const { colorHex, icon, emptyKey, routeName } =
  getModuleData()[props.moduleName];

onMounted(() => {
  store.dispatch('journeyPlanner/setSelectedFilter', filterOptions.value[0]);
});

const journeyPlan = computed(() => {
  return store.getters['journeyPlanner/all'];
});

const isLoading = computed(() => {
  return store.getters['journeyPlanner/isLoading'];
});

const selectedFilter = computed(() => {
  return store.getters['journeyPlanner/selectedFilter'];
});

const moduleColor = computed(() => {
  return `bg-${props?.widget?.colorId}`;
});

const filteredDepartures = computed(() => {
  if (!journeyPlan.value || !journeyPlan.value.length) return [];
  return journeyPlan.value
    .filter((plan) => !!plan.MultiDepartureBoard.Departure)
    .map((data) => data.MultiDepartureBoard.Departure)
    .flat(1)
    .sort(
      (a, b) =>
        formatDepartureTime(a.date, a.time) -
        formatDepartureTime(b.date, b.time)
    );
});

function getDepartureColor(modeOfTransport) {
  switch (modeOfTransport) {
    case 'S':
    case 'M':
      return 'bg-journeyPlanner-dark-red';
    case 'BUS':
      return 'bg-journeyPlanner-yellow';
    case 'IC':
      return 'bg-white';
    case 'REG':
      return 'bg-white';
    default:
      return 'bg-grey';
  }
}

function selectFilter(index) {
  store.dispatch(
    'journeyPlanner/setSelectedFilter',
    filterOptions.value[index]
  );

  store.dispatch('journeyPlanner/getJourneyPlan');
}

function formatDepartureTime(date, time) {
  const [hour, minute] = time.split(':');
  const [day, month, year] = date.split('.');

  const currentYear = getYear(new Date());

  // we're not getting the full year from the API, only "24" instead of "2024"
  const fullYear = `${currentYear.toString().slice(0, 2)}${year}`;
  return new Date(
    fullYear,
    parseInt(month) - 1,
    parseInt(day) - 1,
    hour,
    minute
  );
}
</script>

<template>
  <BaseContentCard
    v-if="journeyPlan.length"
    class="bg-macaron-100"
    :routeName="routeName"
    :backgroundColor="colorHex"
    :emptyKey="emptyKey"
    :shouldShowDate="false"
    :useBackgroundImage="false"
    :showFooter="false"
  >
    <template v-slot:main>
      <div
        :class="moduleColor"
        class="rounded-xl relative h-full overflow-hidden"
      >
        <div class="absolute w-full h-full overflow-y-scroll pb-18">
          <div
            class="flex flex-col justify-center items-stretch pt-4 pb-32 px-4"
          >
            <TransitionGroup
              v-if="!isLoading && journeyPlan"
              name="fade-out-in"
              tag="ul"
            >
              <li
                v-for="departure in filteredDepartures"
                :key="departure.JourneyDetailRef.ref"
                class="flex shadow-lg my-1 bg-journeyPlanner-dark-default h-14 bg-gradient-to-l from-transparent-white rounded-lg mb-2 pr-2"
              >
                <!-- Departure type -->
                <div
                  class="flex justify-center w-14 h-14 rounded-lg mr-2"
                  :class="getDepartureColor(departure.type)"
                >
                  <div
                    class="self-center font-bold text-center"
                    :class="[departure.type === 'S' ? 'text-white' : '']"
                  >
                    {{ departure.name }}
                  </div>
                </div>

                <!-- Departure name -->
                <div
                  class="flex flex-col flex-1 justify-center rounded-lg text-white min-w-0"
                >
                  <p class="truncate text-xs">
                    {{ departure.stop }}
                  </p>

                  <p class="truncate font-bold text-xs">
                    {{ departure.direction }}
                  </p>
                </div>

                <div v-if="departure.rtTime" class="self-center text-white">
                  <div class="font-bold text-2xl leading-none">
                    {{ departure.rtTime }}
                  </div>
                  <div
                    class="bg-error rounded-sm text-center text-xs w-max px-2 mx-auto text-white"
                  >
                    {{ $t('journeyPlanner.newTime') }}
                  </div>
                </div>

                <p
                  v-else
                  class="self-center font-bold text-2xl w-18 text-white"
                >
                  {{ departure.time }}
                </p>
              </li>
            </TransitionGroup>
          </div>
        </div>

        <transition name="fade-out-in">
          <div
            v-if="isLoading"
            class="isLoading w-full h-full bg-transparent"
          />
        </transition>

        <div
          class="flex flex-col bg-gradient-to-t from-charcoal via-charcoal absolute bottom-0 w-full pt-12 pb-4 pb-3 pointer-events-none"
        >
          <p class="text-center text-white text-3xl font-semibold mb-8 pt-16">
            {{ $t('travelPlan.moduleName') }}
          </p>
          <div
            class="tabs flex items-center justify-between rounded-full max-w-xs mx-auto p-1 text-white text-xs pointer-events-auto"
          >
            <p
              v-for="(filter, index) in filterOptions"
              :key="index"
              :class="{
                'bg-white text-black rounded-full':
                  selectedFilter.value === filter.value,
              }"
              class="px-4 opacity-100 whitespace-nowrap"
              @click="selectFilter(index, filter)"
            >
              {{ $t(`${filter.text}`) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </BaseContentCard>

  <WidgetEmptyCard
    v-else
    :emptyKey="emptyKey"
    :backgroundColor="colorHex"
    :icon="icon"
    iconType="fas"
  />
</template>

<style lang="scss" scoped>
.isLoading {
  background-color: transparent !important;
  &::before {
    width: 120px;
    height: 30px;
  }
}
.tabs {
  background: rgba(0, 0, 0, 0.25);
}
.fade-out-in-enter-active,
.fade-out-in-leave-active {
  transition: all 0.5s;
}
.fade-out-in-enter-active {
  transition-delay: 0.5s;
}
.fade-out-in-enter,
.fade-out-in-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
