<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { isItemNow } from '@utils/helpers.js';
import EmployeeComponent from '@components/workSchedule/EmployeeComponent.vue';

const store = useStore();

const props = defineProps({
  shifts: {
    type: Array,
    default: () => [],
  },
  module: {
    type: Object,
    default: () => {},
  },
  gridSize: {
    type: Number,
    default: 9,
  },
  isNormalWs: {
    type: Boolean,
    default: true,
  },
});

const employeeData = computed(() => {
  return props.gridSize === 9 ? getNineGrid() : getFourGrid();
});

function getNineGrid() {
  const data = {};

  // 2 x 2
  if (props.module.frWidth === 2 && props.module.frHeight === 2) {
    if (props.shifts.length < 2) data.cols = props.shifts.length;
    if (props.shifts.length > 2) data.cols = 2;
    if (props.shifts.length > 6) data.cols = 3;
    if (props.shifts.length > 9) data.cols = 4;

    if ([1, 2].includes(data.cols)) data.imageWidth = 200;
    if (data.cols === 3) data.imageWidth = 160;
    if (data.cols === 4) data.imageWidth = 120;
  }

  // 2 x 1
  if (props.module.frWidth === 2 && props.module.frHeight === 1) {
    if (props.shifts.length < 4) data.cols = props.shifts.length;
    if (props.shifts.length === 4) data.cols = 2;
    if (props.shifts.length > 4) data.cols = 3;
    if (props.shifts.length > 6) data.cols = 4;

    if ([1, 2, 3].includes(data.cols) && props.shifts.length < 4) {
      data.imageWidth = 160;
      return data;
    }

    if ([1, 2, 3].includes(data.cols) && props.shifts.length > 3) {
      data.imageWidth = 100;
      return data;
    }

    data.imageWidth = 100;
  }

  // 1 x 1
  if (props.module.frWidth === 1 && props.module.frHeight === 1) {
    if (props.shifts.length <= 2) data.cols = props.shifts.length;
    if (props.shifts.length > 2) data.cols = 2;

    if (data.cols === 1) data.imageWidth = 160;
    if (data.cols === 2) data.imageWidth = 100;
  }

  return data;
}

function getFourGrid() {
  const data = {};

  // 2 x 1
  if (props.module.frWidth === 2 && props.module.frHeight === 1) {
    if (props.shifts.length === 1) data.cols = 1;
    if (props.shifts.length === 2) data.cols = 2;
    if (props.shifts.length > 2) data.cols = 2;
    if (props.shifts.length > 4) data.cols = 3;
    if (props.shifts.length > 6) data.cols = 4;
    if (props.shifts.length > 12) data.cols = 5;

    if ([1, 2].includes(data.cols)) data.imageWidth = 220;
    if (data.cols === 3) data.imageWidth = 190;
    if (data.cols === 4) data.imageWidth = 110;
    if (data.cols === 4 && props.shifts.length < 9) {
      data.imageWidth = 140;
      return data;
    }
    if (data.cols > 4) data.imageWidth = 110;
  }

  // 1 x 1
  if (props.module.frWidth === 1 && props.module.frHeight === 1) {
    if ([1, 2].includes(props.shifts.length)) data.cols = 1;
    if ([3, 4].includes(props.shifts.length)) data.cols = 2;

    if (props.shifts.length > 4) data.cols = 3;

    if ([1, 2].includes(data.cols)) data.imageWidth = 220;
    if ([3, 4].includes(data.cols)) data.imageWidth = 110;
  }

  return data;
}

function hasUnavailability(shift) {
  if (props.module.type === 'LATER_WORK_SCHEDULE') return false;
  if (!shift.unavailable) return false;
  return isItemNow(shift.unavailable[0].start, shift.unavailable[0].end);
}

function employeeClicked(shift) {
  store.dispatch('workSchedule/setSelectedShift', shift);

  setTimeout(() => {
    store.dispatch('general/setActiveOverlay', {
      name: 'work-schedule-entry',
      data: { ...shift },
    });
  }, 50);
}
</script>

<template>
  <div
    class="p-4 grid gap-y-6 flex flex-1"
    :class="`grid-cols-${employeeData.cols}`"
  >
    <EmployeeComponent
      class="item"
      v-for="shift in shifts"
      :key="shift"
      :image="shift.employee.displayImage"
      :name="shift.employee.displayName"
      :start="shift.start"
      :end="shift.end"
      :imageWidth="employeeData.imageWidth"
      :showProgressBar="props.module.type === 'WORK_SCHEDULE'"
      :showTime="
        props.module.type === 'WORK_SCHEDULE' ||
        (props.module.type === 'LATER_WORK_SCHEDULE' && props.isNormalWs)
      "
      :hasUnavailability="hasUnavailability(shift)"
      :isCancelled="shift.cancelled"
      :isNightShift="shift.nightShift"
      @click="employeeClicked(shift)"
    />
  </div>
</template>

<style></style>
