<template>
  <div>
    <button
      :class="[
        'flex border-neutral400',
        'border-2',
        'rounded-full',
        'shadow-lg',
        'bg-white',
        'items-center',
        'justify-center',
        containerStyles,
      ]"
      :style="
        !this.title
          ? { width: '76px', height: '76px' }
          : { width: '185px', height: '76px' }
      "
      @click="$emit('click')"
    >
      <div class="w-6 h-6 flex items-center justify-center" v-if="icon">
        <fa-icon :icon="icon" class="fa-2xl" />
      </div>
      <span
        v-if="title"
        :class="{ 'pl-4': icon, capitalize: capitalizeTitle }"
        class="font-bold text-xl"
        >{{ title }}</span
      >
    </button>
  </div>
</template>

<script>
export default {
  name: 'IconButton',
  emits: ['click'],
  props: {
    title: {
      type: String,
      default: null,
    },
    capitalizeTitle: {
      type: Boolean,
      default: false,
    },
    /**
     * If you need define the fa-icon style as well,
     * pass the icon prop as an array:
     * <IconButton :icon="['far', 'calendar-plus']" />
     * <IconButton :icon="['fal', 'times']" />
     */
    icon: {
      type: [String, Array],
      default: null,
    },
    onClick: {
      type: Function,
      default: () => console.log('Default onClick'),
    },
  },
  computed: {
    containerStyles() {
      // Title and icon
      if (this.title && this.icon) {
        return 'px-6 py-3';
      }
      // Title only
      if (this.title && !this.icon) {
        return 'px-6 py-3';
      }
      // Icon only
      if (!this.title && this.icon) {
        return 'p-6 justify-center';
      }
    },
  },
};
</script>
