<script setup>
import { ref, computed } from 'vue';
import { VueperSlides, VueperSlide } from 'vueperslides';
import CarouselControls from '@components/shared/CarouselControls.vue';
import ArchiveControls from '@components/shared/ArchiveControls.vue';
import { useStore } from 'vuex';
import { YoutubeIframe } from '@vue-youtube/component';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import VideoControls from '@components/shared/VideoControls.vue';

const store = useStore();
const vueperSlides = ref();
const autoPlaying = ref(false);
const internalAutoPlaying = ref(false);
const sliderReady = ref(false);
const youtube = ref();
const vimeo = ref();
const dreambroker = ref();
const currentVideoProvider = ref();
const currentSlide = ref();

const emit = defineEmits(['archive-add', 'archive-remove', 'close-preview']);

const lang = computed(() => store.getters['institution/locale']);

const props = defineProps({
  videos: {
    type: Array,
    default: () => [],
  },
  selectedItemIndex: {
    type: Number,
    default: 0,
  },
  allowArchiveRemove: {
    type: Boolean,
    default: false,
  },
});

const playerVars = {
  cc_load_policy: 1,
  cc_lang_pref: lang.value,
  autoplay: 1,
};

async function handleVideoEvent(event) {
  if (!event) return;

  switch (currentVideoProvider.value) {
    case 'YOUTUBE':
      youtube.value?.[0]?.instance[event]();
      return;
    case 'VIMEO':
      if (event === 'playVideo') {
        vimeo.value[0].play();
      } else if (event === 'pauseVideo') {
        vimeo.value[0].pause();
      } else {
        const videoId = parseInt(
          /([^\/]+$)/.exec(props.videos[currentSlide.value].url)[0]
        );
        vimeo.value[0].update(videoId);
      }
      return;
    default:
      console.log('No provider');
  }
}

function onSlide(event) {
  currentSlide.value = event.currentSlide.index;
  setCurrentProvider();
}

function setCurrentProvider() {
  currentVideoProvider.value =
    props.videos[currentSlide.value || props.selectedItemIndex].provider;
}

function onReady() {
  sliderReady.value = true;
  vueperSlides.value.goToSlide(props.selectedItemIndex);
  setCurrentProvider();
}

function goNext() {
  vueperSlides.value.next();
}

function goPrevious() {
  vueperSlides.value.previous();
}

function archiveAddRemove(addOrRemove) {
  emit(`archive-${addOrRemove}`, {
    mediaType: 'videos',
    id: props.videos[currentSlide.value].id,
  });
}

function getYoutubeId(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}
</script>

<template>
  <div class="flex flex-col items-center justify-center">
    <VueperSlides
      ref="vueperSlides"
      class="w-full mb-8 no-shadow"
      fixed-height="550px"
      :start-index="props.selectedItemIndex"
      :arrows="false"
      :bullets="false"
      :touchable="true"
      :draggingDistance="300"
      :style="{ width: '950px' }"
      :infinite="false"
      @ready="onReady()"
      @slide="onSlide($event)"
      @autoplay-pause="internalAutoPlaying = false"
      @autoplay-resume="internalAutoPlaying = true"
    >
      <VueperSlide
        v-for="(video, index) in videos"
        :key="index"
        :class="`slide-${index}`"
      >
        <template #content class="flex items-center justify-center">
          <div class="pointer-events-none">
            <div
              v-if="
                video.provider === 'YOUTUBE' &&
                currentSlide === index &&
                currentVideoProvider === 'YOUTUBE'
              "
            >
              <youtube-iframe
                ref="youtube"
                :video-id="getYoutubeId(video.url)"
                :width="950"
                :height="550"
                :playerVars="playerVars"
              />
            </div>

            <div
              v-if="
                video.provider === 'VIMEO' &&
                currentSlide === index &&
                currentVideoProvider === 'VIMEO'
              "
            >
              <vueVimeoPlayer
                ref="vimeo"
                :video-id="video.url"
                :player-height="550"
                :player-width="950"
                :autoplay="true"
              >
              </vueVimeoPlayer>
            </div>
            <div
              v-if="
                video.provider === 'DREAMBROKER' &&
                currentSlide === index &&
                currentVideoProvider === 'DREAMBROKER'
              "
            >
              <iframe
                ref="dreambroker"
                class="w-full"
                height="550"
                :src="`${video.url}?autoplay=true`"
                allow="autoplay"
              />
            </div>
          </div>
        </template>
      </VueperSlide>
    </VueperSlides>

    <video-controls
      v-if="currentVideoProvider !== 'DREAMBROKER'"
      class="mb-28"
      @playVideo="handleVideoEvent('playVideo')"
      @pauseVideo="handleVideoEvent('pauseVideo')"
      @resetView="handleVideoEvent('stopVideo')"
    />

    <archive-controls
      v-if="sliderReady"
      :allowArchiveRemove="allowArchiveRemove"
      mediaType="videos"
      @archive-add="archiveAddRemove('add')"
      @archive-remove="archiveAddRemove('remove')"
    ></archive-controls>

    <carousel-controls
      v-if="sliderReady"
      :total-slides="videos.length"
      :current-slide="$refs.vueperSlides.slides.current + 1"
      media-type="VIDEOS"
      :autoplay="autoPlaying"
      @autoplay-toggled="
        $refs.vueperSlides[`${autoPlaying ? 'pause' : 'resume'}Autoplay`]();
        autoPlaying = !autoPlaying;
      "
      @previous="goPrevious()"
      @next="goNext()"
      @close="$emit('close-preview')"
    />
  </div>
</template>
