<template>
  <div>
    <div
      class="flex justify-between self-center px-20 pb-20 -mt-12 rounded-t-3xl"
    >
      <div class="largeListItemHeader">
        {{ $t(data.title) }}
      </div>
    </div>

    <div
      v-for="(item, index) in data.items"
      :key="index"
      class="participants content inline-flex w-full pb-10 px-20 items-center"
    >
      <img
        v-if="item.picture !== null"
        :src="item.picture.source"
        :class="{ '-m-1': index !== 0 }"
        class="w-24 h-24 rounded-full border border-gray-200 mr-10"
      />
      <div
        v-else
        :class="{ '-m-1': index !== 0 }"
        class="w-24 h-24 mr-10 flex items-center justify-center"
      >
        <div class="w-5 h-5 rounded-full bg-black"></div>
      </div>
      <p class="label">
        {{ item.title }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      activeOverlay: "general/activeOverlay",
    }),
    data() {
      return this.activeOverlay.data;
    },
  },
};
</script>
