<template>
  <div class="overflow-hidden">
    <div class="flex overflow-x-scroll">
      <div
        v-for="( date, index ) in daysAhead()"
        :key="index"
        :style="getStyles(index)"
        class="flex flex-col items-center justify-center h-32 p-4 mr-6 rounded rounded-md border border-sm relative"
        @click="dateSelected(index)"
      >
        <fa-icon
          v-if="isUnavailable(index)"
          :icon="['fa', 'slash']"
          class="text-error transform rotate-90 absolute text-6xl"
        />
        <div
          v-if="isToday(index)"
          class="w-20 flex flex-col"
        >
          <p class="text-center text-3xl font-bold">
            {{ getFormattedDate(index) }}
          </p>
        </div>

        <div
          v-else
          class="w-20 flex flex-col"
        >
          <p class="text-center">
            {{ getFormattedDate(index)[0] }}
          </p>
          <p class="text-center text-4xl font-bold">
            {{ getFormattedDate(index)[1] }}
          </p>
          <p class="text-center">
            {{ getFormattedDate(index)[2] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addDays, isToday, differenceInDays } from 'date-fns';
import { format } from '@utils/date-fns';
import { capitalize } from 'lodash';
import { hexToRgbA } from '@utils/helpers';
import colors from '@utils/colors';

export default {
  props: {
    initialDate: {
      type: Date,
      'default': (() => { return new Date() })
    },
    color: {
      type: String,
      default: ''
    },
    endDate: {
      type: Date,
      'default': (() => {})
    },
    unavailableDates: {
      type: Array,
      'default': (() => [])
    }
  },
  data() {
    return {
      selectedIndex: 0
    }
  },
  computed: {
    colorLight() {
      return hexToRgbA(this.color, 0.05);
    },
    inactiveColor() {
      return hexToRgbA(colors.charcoal, 0.25);
    },
  },
  methods: {
    isToday(index) {
      return isToday(addDays(this.initialDate, index), new Date());
    },
    getFormattedDate(index) {
      if (this.isToday(index)) return this.$t('global.today');
      const date = capitalize(format(addDays(this.initialDate, index), 'EEEE dd MMMM'));
      return date.split(' ');
    },
    dateSelected(index) {
      if (this.isUnavailable(index)) return;
      this.selectedIndex = index;
      this.$emit('date-selected', index);
    },
    daysAhead() {
      if (!this.endDate) return 14;
      return differenceInDays(new Date(this.endDate), this.initialDate) + 1;
    },
    isUnavailable(index) {
      const today = new Date(this.initialDate);
      return this.unavailableDates.includes(format(addDays(today, index), 'yyyy-MM-dd'));
    },
    getStyles(index) {
      if (this.selectedIndex === index) {
        return {
          'background-color': this.color,
          'border-color': this.color,
          'color': '#fff'
        }
      }

      if (this.isUnavailable(index)) {
        return {
          'background-color': '#fff',
          'border-color': this.inactiveColor,
          'color': this.inactiveColor
        }
      }

      return {
        'background-color': this.colorLight,
        'border-color': this.color,
        'color': this.color
      };
    }
  }
}
</script>