<template />
<script>
import { useIdle } from '@vueuse/core';
import { mapGetters } from 'vuex';
/**
 * Creates a watcher to set idle status is store, when idle time is exceeded.
 * We watch the idle component in createIdleWatcher, and try to reset the store value, which is an abstraction and handles our own business logic,
 * to decide when we pause idle checks or not.
 *
 * We reset the local idle watcher timer here, for example, to prevent, screen from staying in the same idle state over night, causing data to not be refetched.
 */
export default {
  name: 'IdleWatcher',

  computed: {
    ...mapGetters({
      settings: 'department/settings',
      idleTimeout: 'department/idleTimeout',
    }),
  },

  mounted() {
    // Initialize the idle watcher.
    this.createIdleWatcher(this.idleTimeout);
  },

  watch: {
    // Create a new watcher when the timeout setting changes.
    idleTimeout(newTimeout) {
      this.createIdleWatcher(newTimeout);
    },
  },

  methods: {
    // Create an idle object using useIdle and setup a watcher on the idle object's value to update the state when it changes.
    createIdleWatcher(timeout) {
      const timeoutMS = timeout * 1000; // BE uses seconds and useIdle uses ms.
      const { idle, reset } = useIdle(timeoutMS);

      this.$watch(
        () => idle.value,
        (to) => {
          this.$store.commit('idleHandler/SET_IDLE_STATUS', to);

          // Reset will refresh the idle state, briefly setting state true then false, causing screen to only be in the idle shortly,
          // triggering business logic that reacts to the idle state switching.
          reset();
        }
      );
    },
  },
};
</script>
