<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import DateSelector from '@components/shared/DateSelector.vue';
import TimeSelector from '@components/shared/TimeSelector.vue';
import colors from '@utils/colors';
import {
  isToday,
  addDays,
  addMinutes,
  isAfter,
  areIntervalsOverlapping,
  parseISO,
} from 'date-fns';
import { format } from '@utils/date-fns';
import { cloneDeep } from 'lodash';
import { stringToMinutes, minutesToString } from '@utils/helpers';
import { default as ButtonEl } from '@components/shared/Button.vue';
import isSameDay from 'date-fns/isSameDay';

const store = useStore();
const emit = defineEmits(['close-overlay']);

const selected = ref({
  startDate: format(new Date(), 'yyyy-MM-dd'),
  startTime: '',
  endDate: format(new Date(), 'yyyy-MM-dd'),
  endTime: '',
});

const activeOverlay = computed(() => store.getters['general/activeOverlay']);

const resource = computed(
  () => store.getters['bookingResources/selectedBookingResource']
);

const data = computed(() => {
  return activeOverlay.value.data;
});

const moduleColor = computed(() => {
  return colors['BOOKING'];
});

const startDateObject = computed(() => {
  return new Date(selected.value.startDate);
});

const endDateObject = computed(() => {
  return new Date(selected.value.endDate);
});

const futureUnavailabilities = computed(() => {
  return cloneDeep(unavailable.value).filter((item) => {
    return isAfter(new Date(item.start), selectedStartDateTime.value);
  });
});

const unavailable = computed(() => {
  return cloneDeep(resource.value).bookings.map((booking) => {
    return {
      start: booking.start,
      end: booking.end,
    };
  });
});

const firstAvailableTime = computed(() => {
  return minutesToString(stringToMinutes(selected.value.startTime) + 15);
});

const selectedStartDateTime = computed(() => {
  const selectedStartDate = format(startDateObject.value, 'yyyy, MM, dd');
  return new Date(`${selectedStartDate}, ${selected.value.startTime}`);
});

const startTimeFrom = computed(() => {
  const date = new Date();
  const ms = 1000 * 60 * 15;
  const nearestQuarter = format(
    new Date(Math.floor(date.getTime() / ms) * ms),
    'HH:mm'
  );

  return isToday(startDateObject.value) ? nearestQuarter : '00:00';
});

const startTimeTo = computed(() => {
  return isSameDay(startDateObject.value, endDateObject.value)
    ? firstAvailableTime.value
    : '00:00';
});

const endDateTo = computed(() => {
  if (!futureUnavailabilities.value.length) return addDays(new Date(), 14);
  return new Date(futureUnavailabilities.value[0].start);
});

const endTimeTo = computed(() => {
  if (
    futureUnavailabilities.value.length &&
    isSameDay(
      new Date(selected.value.endDate),
      new Date(futureUnavailabilities.value[0].start)
    )
  ) {
    return format(new Date(futureUnavailabilities.value[0].start), 'HH:mm');
  }

  return '23:45';
});

const canGoNext = computed(() => {
  return (
    selected.value.startDate &&
    selected.value.startTime &&
    selected.value.endDate &&
    selected.value.endTime
  );
});

const allTimeSlots = computed(() => {
  const end = stringToMinutes('23:45');

  const dates = [];

  for (let i = 0; i < 14; i++) {
    dates.push(format(addDays(new Date(), i), 'yyyy-MM-dd'));
  }

  return dates.map((date) => {
    const allSlots = Array.from({ length: Math.floor(end / 15) + 1 }, (_, i) =>
      minutesToString(i * 15)
    );

    return {
      date,
      slots: allSlots.map((slot) => {
        return {
          time: slot,
          available: isTimeAvailable(date, slot),
        };
      }),
    };
  });
});

const futureTimeslots = computed(() => {
  const parsedEndDate = parseISO(selected.value.endDate);
  const parsedStartDate = parseISO(selected.value.startDate);
  const isEndingSameDay = isSameDay(parsedEndDate, parsedStartDate);
  const earliestSlotTime =
    selected.value.startTime === ''
      ? startTimeFrom.value
      : selected.value.startTime;

  if (!isEndingSameDay) {
    return getTimeSlots();
  }

  return getTimeSlots().filter((slot) => slot.time > earliestSlotTime);
});

const unavailableDates = computed(() => {
  return cloneDeep(allTimeSlots.value)
    .filter((slot) => slot.slots.filter((slot) => slot.available).length < 1)
    .map((item) => item.date);
});

onMounted(() => {
  window._paq.push(['trackEvent', 'NewBooking', 'Viewed']);
});

function isTimeAvailable(date, slot) {
  if (!unavailable.value.length) return true;
  const slotDate = format(new Date(date), 'yyyy, MM, dd');
  const slotDateTime = new Date(`${slotDate}, ${slot}`);

  return (
    cloneDeep(unavailable.value).filter((range) => {
      const rangeStart = new Date(range.start);
      const rangeEnd = new Date(range.end);

      return areIntervalsOverlapping(
        { start: rangeStart, end: rangeEnd },
        { start: slotDateTime, end: addMinutes(slotDateTime, 15) }
      );
    }).length < 1
  );
}

function startDateSelected(amount) {
  const today = new Date();
  selected.value.startDate = format(addDays(today, amount), 'yyyy-MM-dd');
  selected.value.endDate = selected.value.startDate;
}

function endDateSelected(amount) {
  selected.value.endDate = format(
    addDays(new Date(selected.value.startDate), amount),
    'yyyy-MM-dd'
  );
}

function startTimeSelected(event) {
  selected.value.startTime = event;
  selected.value.endTime = '';
}

async function next() {
  await store.dispatch('bookingResources/setNewBooking', {
    selectedStartDate: selected.value.startDate,
    selectedStartTime: selected.value.startTime,
    selectedEndDate: selected.value.endDate,
    selectedEndTime: selected.value.endTime,
  });

  store.dispatch('general/setNextActiveOverlay', {
    name: 'new-booking-description',
  });

  emit('close-overlay');
}

function getTimeSlots() {
  const index = allTimeSlots.value.findIndex(
    (slot) => slot.date === selected.value.startDate
  );

  return cloneDeep(allTimeSlots.value)[index].slots;
}
</script>

<template>
  <div>
    <div
      class="flex justify-between self-center px-20 pb-20 -mt-12 rounded-t-3xl"
    >
      <div class="largeListItemHeader">
        {{ $t(data.title) }}
      </div>
    </div>

    <div class="pb-10 pl-20">
      <p class="content bold mb-4">
        {{ $t('global.startDate') }}
      </p>

      <date-selector
        :color="moduleColor"
        :unavailable-dates="unavailableDates"
        @date-selected="startDateSelected"
      />
    </div>

    <div class="w-full pb-10 px-20">
      <p class="content bold mb-4">
        {{ $t('global.startTime') }}
      </p>

      <time-selector
        type="start"
        :selected-date="selected.startDate"
        :start-time="startTimeFrom"
        end-time="23:45"
        :color="moduleColor"
        :time-slots="getTimeSlots()"
        @time-selected="startTimeSelected"
        @deselect="selected.startTime = ''"
      />
    </div>

    <div class="pb-10 pl-20">
      <p class="content bold mb-4">
        {{ $t('global.endDate') }}
      </p>

      <date-selector
        :color="moduleColor"
        :end-date="endDateTo"
        :initial-date="startDateObject"
        @date-selected="endDateSelected"
      />
    </div>

    <div class="w-full pb-10 px-20">
      <p class="content bold mb-4">
        {{ $t('global.endTime') }}
      </p>

      <time-selector
        type="end"
        :selected-date="selected.endDate"
        :start-time="startTimeTo"
        :end-time="endTimeTo"
        :color="moduleColor"
        :time-slots="futureTimeslots"
        @time-selected="selected.endTime = $event"
        @deselect="selected.endTime = ''"
      />
    </div>

    <div v-if="canGoNext" class="flex w-full justify-center">
      <button-el
        text="modals.next"
        icon="arrow-right"
        background-color="success"
        text-color="white"
        class="mr-5 mt-8 shadow-xsm"
        @click="next"
      />
    </div>
  </div>
</template>
