<script setup>
import { useStore } from 'vuex';
import { default as ButtonEl } from '@components/shared/Button.vue';
import PinFlow from '@utils/pinFlow';
import { computed, defineEmits } from 'vue';

const store = useStore();
const emit = defineEmits(['close-overlay']);

const data = computed(() => store.getters['general/activeOverlay'].data);

function back() {
  store.dispatch('general/setNextActiveOverlay', {
    name: 'replace-shift-employee',
    data: { selectedShift: data.value.selectedShift, action: 'replace' },
  });

  emit('close-overlay');
}

function replace() {
  emit('close-overlay');

  setTimeout(() => {
    const flow = new PinFlow({
      entity: 'shift',
      action: data.value.action,
      employeeName: data.value.selectedShift.employee.displayName,
      employee: data.value.selectedProfile,
    });

    flow.startUpdateShiftFlow('shift');
  }, 700);
}
</script>

<template>
  <div class="h-auto">
    <div
      class="flex justify-between self-center px-20 pb-48 -mt-32 rounded-t-3xl"
    >
      <div class="largeListItemHeader">
        {{
          $t('scheduleShift.replaceWith') + data.selectedProfile.displayName
        }}?
      </div>
    </div>
    <div class="flex justify-center items-center py-24">
      <div class="profile flex flex-col items-center opacity-60">
        <img
          class="profileImage rounded-full shadow-lg object-cover border-8 border-white mb-4 h-60 w-60"
          :src="data.selectedShift.employee.displayImage"
        />
        <p class="text-2xl font-bold text-center">
          {{ data.selectedShift.employee.displayName }}
        </p>
      </div>
      <div class="profile flex flex-col items-center -ml-12 z-10">
        <img
          class="profileImage rounded-full shadow-lg object-cover border-8 border-white mb-4 h-60 w-60"
          :src="data.selectedProfile.displayImage"
        />
        <p class="text-2xl font-bold text-center">
          {{ data.selectedProfile.displayName }}
        </p>
      </div>
    </div>
    <div class="flex justify-center pt-72 gap-3">
      <button-el
        text="scheduleShift.replace"
        icon="fa-exchange"
        :shadow="true"
        class="self-center"
        background-color="success"
        @click="replace()"
      />
      <button-el
        text="global.cancel"
        icon="undo"
        :shadow="true"
        class="self-center"
        background-color="white"
        @click="back()"
      />
    </div>
  </div>
</template>
