import { ApolloClient, InMemoryCache, from } from '@apollo/client/core';
import fetch from 'unfetch';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import * as Sentry from '@sentry/vue';

const httpLink = createHttpLink({ uri: import.meta.env.VITE_API_URL, fetch });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('api_token');

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const retryIf = (error) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError?.statusCode && networkError.statusCode === 401) {
    auth.authenticate();
  }

  // Create temporary scope and add info to error.
  Sentry.withScope((scope) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        scope.setExtra('graphql_error', error);
        console.error(`GraphQL error: ${JSON.stringify(error, null, 2)}`);
      });
      Sentry.captureException(new Error('GraphQL error'), scope);
    }

    if (networkError) {
      console.error(`Network error: ${JSON.stringify(networkError, null, 2)}`);
      scope.setTag('network_error', networkError.name);
      scope.setExtra('network_error', networkError);
      if (networkError.response) {
        console.error(
          `Network error response: ${JSON.stringify(networkError.response, null, 2)}`
        );
        scope.setExtra('network_error_response', networkError.response);
      }
      if (networkError.result) {
        console.error(
          `Network error result: ${JSON.stringify(networkError.result, null, 2)}`
        );
        scope.setExtra('network_error_result', networkError.result);
      }
      Sentry.captureException(new Error('Network error'), scope);
    }
  });
});

export const apolloClient = new ApolloClient({
  link: from([authLink, retryLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
