function getLockedUsers() {
  return JSON.parse(localStorage.getItem('lockedUsers')) || [];
}

export async function attemptFailed(userId, institutionSettings) {
  const lockedUsers = getLockedUsers();
  const user = lockedUsers.find((lockedUser) => lockedUser.id === userId);

  const lockOutDuration =
    institutionSettings?.screen?.passwordProtection?.userLockedOutInMinutes ||
    0;

  const maxFailedAttempts =
    institutionSettings?.screen?.passwordProtection?.maxFailed || undefined;

  const pinAttempts = user ? user.pinAttempts : 1;
  const timestamp = Date.now() + lockOutDuration * 60 * 1000;

  if (user) {
    user.pinAttempts = user.pinAttempts + 1;
    user.pinLocked = pinAttempts >= maxFailedAttempts - 1;
    user.timestamp = timestamp;
  } else {
    lockedUsers.push({
      id: userId,
      pinLocked: pinAttempts >= maxFailedAttempts - 1,
      pinAttempts: 1,
      timestamp,
    });
  }

  localStorage.setItem('lockedUsers', JSON.stringify(lockedUsers));
}

export async function cleanLockedUsers() {
  let lockedUsers = getLockedUsers();

  lockedUsers = lockedUsers.filter((user) => {
    const newDate = Math.floor(new Date(Date.now()).getTime());
    return newDate < user.timestamp;
  });

  localStorage.setItem('lockedUsers', JSON.stringify(lockedUsers));
}

export function isUserLocked(userId) {
  const lockedUsers = getLockedUsers();
  const user = lockedUsers.find((lockedUser) => lockedUser.id === userId);

  if (!user) {
    return {
      locked: false,
      duration: -1,
    };
  }

  const newDate = Math.floor(new Date(Date.now()).getTime() / 1000);
  const userDate = Math.floor(user.timestamp / 1000);

  return {
    locked: user?.pinLocked || false,
    duration: userDate - newDate,
  };
}
