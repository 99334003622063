<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { get, cloneDeep } from 'lodash';
import {
  isPast,
  addDays,
  endOfDay,
  isThisWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from 'date-fns';
import { format } from '@utils/date-fns';
import { hexToRgbA, getWeekday } from '@utils/helpers';
import colors from '@utils/colors';
import { default as ButtonEl } from '@components/shared/Button.vue';
import PinFlow from '@utils/pinFlow';

const store = useStore();

const opt = ref('');

const activeOverlay = computed(() => store.getters['general/activeOverlay']);
const resource = computed(
  () => store.getters['bookingResources/selectedBookingResource']
);
const originalSlots = computed(
  () => store.getters['bookingResources/selectedBookingSlots']
);
const selectedSlottedWeek = computed(
  () => store.getters['bookingResources/selectedSlottedWeek']
);
const activeProfile = computed(() => store.getters['profiles/activeProfile']);

const data = computed(() => {
  return activeOverlay.value.data;
});

// Groups booking slots by id's, which will match if the 'time of day' is the same, creating an array for each timeslot.
// Each array contains slots that occur at the same time of day across different dates e.g: mon-sun 10:00-10:20.
const rows = computed(() => {
  const rows = cloneDeep(originalSlots.value).reduce((rv, x) => {
    (rv[x.id] = rv[x.id] || []).push(x);
    return rv;
  }, {});

  return rows;
});

const lightGray = computed(() => {
  return hexToRgbA(colors.charcoal, 0.05);
});

const mediumGray = computed(() => {
  return hexToRgbA(colors.charcoal, 0.25);
});

const moduleColor = computed(() => {
  return colors.BOOKING;
});

const canGoPrevious = computed(() => {
  return !isThisWeek(selectedSlottedWeek.value.weekStart);
});

const weekNumber = computed(() => {
  return getWeek(selectedSlottedWeek.value.weekStart);
});

onMounted(() => {
  getBookingSlots();
});

function getBookingSlots() {
  store.dispatch('bookingResources/getBookingSlots', {
    id: resource.value.id,
    dateFrom: format(selectedSlottedWeek.value.weekStart, 'yyyy-MM-dd'),
    dateTo: format(selectedSlottedWeek.value.weekEnd, 'yyyy-MM-dd'),
  });
}

function formatTime(dateTime) {
  if (!dateTime) return '!date';
  return format(new Date(dateTime), 'HH:mm');
}

function getIsPast(slot) {
  return isPast(new Date(slot.end));
}

function isPastByIndex(index) {
  const day = endOfDay(addDays(selectedSlottedWeek.value.weekStart, index - 1));
  return getIsPast(day);
}

function getDay(index) {
  return getWeekday(addDays(selectedSlottedWeek.value.weekStart, index - 1));
}

function getDate(index) {
  return format(
    addDays(selectedSlottedWeek.value.weekStart, index - 1),
    'dd MMMM'
  );
}

function saveWeekToStore(weekStart, weekEnd) {
  store.dispatch('bookingResources/setSelectedSlottedWeek', {
    weekStart,
    weekEnd,
  });
}

async function getPreviousWeek() {
  if (!canGoPrevious.value) return;
  const newStart = subWeeks(selectedSlottedWeek.value.weekStart, 1);
  const newEnd = subWeeks(selectedSlottedWeek.value.weekEnd, 1);
  saveWeekToStore(newStart, newEnd);
  getBookingSlots();
}

async function getNextWeek() {
  const newStart = addWeeks(selectedSlottedWeek.value.weekStart, 1);
  const newEnd = addWeeks(selectedSlottedWeek.value.weekEnd, 1);
  saveWeekToStore(newStart, newEnd);
  getBookingSlots();
}

async function getThisWeek() {
  await store.dispatch('bookingResources/setSelectedSlottedWeek');
  getBookingSlots();
}

function canChooseSlot(slot) {
  if (slot.state === 'LOCKED') return;
  if (getIsPast(slot)) return;

  return true;
}

async function slotClicked(slot) {
  if (!canChooseSlot(slot)) return;

  const option = slot.state === 'AVAILABLE' ? 'in' : 'out';
  opt.value = option;
  store.dispatch('bookingResources/setOpt', option);

  const profile = {};

  profile.profileId =
    opt.value === 'in' ? activeProfile.value.id : slot.participants[0]?.id;
  profile.profileType =
    opt.value === 'in'
      ? activeProfile.value.type
      : slot.participants[0].__typename.toUpperCase();

  await store.dispatch('bookingResources/setNewSlottedBooking', {
    bookingSlot: {
      bookingResourceId: resource.value.id,
      bookingResourceSlotId: slot.id,
      date: format(new Date(slot.start), 'yyyy-MM-dd'),
    },
    participants: [profile],
  });

  const flow = new PinFlow({
    entity: 'booking',
    text: 'booking.theBooking',
    opt,
    title: resource.value.title,
    sharedDepartments: resource.value.departments,
  });

  flow.startSignupFlow('booking');
}
</script>

<template>
  <div>
    <div
      class="flex justify-between self-center px-20 pb-20 -mt-12 rounded-t-3xl"
    >
      <div class="largeListItemHeader">
        {{ $t(data.title) }}
      </div>
    </div>

    <div class="w-full px-20 flex justify-center">
      <div
        v-for="(_, index) in 8"
        :key="index"
        class="slot mr-3 p-3 flex items-end justify-center"
      >
        <p v-if="index === 0" />
        <div
          v-else
          class="text-center text-xs font-bold"
          :style="{ color: isPastByIndex(index) ? mediumGray : '#000' }"
        >
          <p>{{ getDay(index) }}</p>
          <p>{{ getDate(index) }}</p>
        </div>
      </div>
    </div>

    <p
      v-if="resource.slotted && !originalSlots.length"
      class="w-full text-center text-error text-2xl mt-12"
    >
      {{ $t('booking.noSchema') }}
    </p>

    <div class="w-full pb-10 px-20">
      <div v-for="(day, i) in rows" :key="i" class="flex justify-center mb-3">
        <div
          class="labelSmall font-bold slot mr-3 flex flex-col items-center justify-center"
        >
          <!-- Use index 0 since days are grouped by timeslot time of day. -->
          <p>{{ formatTime(day?.[0]?.start) }}</p>
          <p>{{ formatTime(day?.[0]?.end) }}</p>
        </div>

        <div
          v-for="(slot, index) in day"
          :key="index"
          class="slot mr-3 p-3 rounded-sm"
          :style="{
            background: lightGray,
            opacity: getIsPast(slot) ? '0.3' : '1',
          }"
        >
          <div
            class="w-full h-full flex items-center justify-center"
            :class="{
              'border-4 rounded-full':
                slot.state !== 'LOCKED' && !slot.participants.length,
            }"
            :style="{ 'border-color': moduleColor }"
            @click="slotClicked(slot)"
          >
            <fa-icon
              v-if="slot.state === 'LOCKED'"
              class="text-5xl"
              :icon="['fas', 'lock']"
            />
            <img
              v-if="slot.participants.length && slot.state !== 'LOCKED'"
              :src="slot.participants[0].displayImage"
              alt="Participant"
              class="w-full h-full bg-cover rounded-full"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="w-full px-20 mt-8 flex justify-center items-center">
      <button-el
        class="flex"
        text=""
        icon="arrow-left"
        :background-color="canGoPrevious ? 'success' : 'charcoal-light'"
        @click="getPreviousWeek"
      />

      <div class="text-3xl mx-8 flex flex-col justify-center items-center">
        <p class="font-semibold">{{ $t('global.week') }} {{ weekNumber }}</p>
        <p class="text-charcoal-light underline" @click="getThisWeek">
          {{ $t('global.thisWeek') }}
        </p>
      </div>

      <button-el
        class="flex"
        text=""
        icon="arrow-right"
        background-color="success"
        @click="getNextWeek"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.slot {
  width: 100px;
  height: 100px;
}
</style>
