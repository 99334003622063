<template>
  <transition
    enter-active-class="animate__animated animate__slideInUp"
    leave-active-class="animate__animated animate__slideOutDown"
    :duration="{ enter: showBackdrop ? 1000 : 0, leave: 800 }"
    @after-enter="showBackdrop = true"
  >
    <div
      v-if="show"
      :style="overlayStyles"
      class="overlay flex flex-col w-full fixed left-0 fixed top-0 z-30"
    >
      <transition
        enter-active-class="animate__animated animate__slideInUp"
        leave-active-class="animate__animated animate__slideOutDown"
        :duration="1000"
      >
        <div
          v-if="showBackdrop"
          class="backdrop bg-white shadow-top pb-12"
          :class="{ 'overflow-y-scroll': overlay.canScroll }"
          :style="backdropStyles"
        >
          <div
            v-if="overlay.canClose"
            class="flex justify-between my-20 mx-20 items-center"
          >
            <div class="largeListItemHeader">
              {{ $t(overlay.title || '') }}
            </div>

            <fa-icon
              :icon="['fal', 'times']"
              class="text-5xl font-bold"
              @click="close(true, true)"
            />
          </div>
          <component
            :is="overlay.name"
            class="min-h-full"
            @close-overlay="close"
          />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import EnterPin from '@views/overlays/EnterPin.vue';
import ProfileSelect from '@views/shared/ProfileSelect.vue';
import ParticipantsList from '@views/overlays/ParticipantsList.vue';
import WorkScheduleEntry from '@views/overlays/WorkScheduleEntry.vue';
import Agenda from '@views/overlays/Agenda.vue';
import Suggestions from '@views/overlays/Suggestions.vue';
import WriteSuggestion from '@views/overlays/WriteSuggestion.vue';
import NewBooking from '@views/overlays/NewBooking.vue';
import NewSlottedBooking from '@views/overlays/NewSlottedBooking.vue';
import NewBookingDescription from '@views/overlays/NewBookingDescription.vue';
import NewBookingParticipants from '@views/overlays/NewBookingParticipants.vue';
import ReplaceShiftEmployee from '@views/overlays/ReplaceShiftEmployee.vue';
import ReplaceShiftConfirmation from '@views/overlays/ReplaceShiftConfirmation.vue';
import OurHomeGallery from '@views/overlays/OurHomeGallery.vue';
import { hexToRgbA } from '@utils/helpers.js';
import overlays from '@utils/overlays';
import { mapGetters } from 'vuex';
import Button from '@components/shared/Button.vue';

export default {
  components: {
    EnterPin,
    ProfileSelect,
    ParticipantsList,
    WorkScheduleEntry,
    Agenda,
    Suggestions,
    WriteSuggestion,
    NewBooking,
    NewSlottedBooking,
    NewBookingDescription,
    NewBookingParticipants,
    ReplaceShiftEmployee,
    ReplaceShiftConfirmation,
    OurHomeGallery,
    ButtonEl: Button,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showBackdrop: false,
    };
  },
  computed: {
    ...mapGetters({
      activeOverlay: 'general/activeOverlay',
      nextActiveOverlay: 'general/nextActiveOverlay',
    }),
    overlay() {
      return overlays.find(
        (overlay) => overlay.name === this.activeOverlay.name
      );
    },
    overlayHeight() {
      return window.innerHeight - this.$constants.navHeight + 50;
    },
    backdropColor() {
      return 'transparent';
    },
    overlayStyles() {
      return {
        backgroundColor: hexToRgbA(this.backdropColor, 0.9) || 'transparent',
        'min-height': this.overlayHeight,
        bottom: this.$constants.navHeight,
        'overflow-y': this.overlay.canScroll ? 'scroll' : 'none',
      };
    },
    backdropStyles() {
      const height = this.overlay.pxHeight || 100;

      return {
        'min-height': `${height}px`,
        marginTop: `${this.overlay.marginTop}px`,
      };
    },
  },
  methods: {
    async close(force, track = false) {
      if (track)
        this.$matomo.trackEvent('Navigation', 'Clicked', 'CloseOverlayX');
      this.$emit('close-overlay', force);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  .backdrop {
    border-radius: 25px 25px 0 0;
  }
}
</style>
