<template>
  <header
    class="flex items-center justify-between border-b-2 border-neutral-300 z-75 bg-white py-6"
  >
    <div class="flex flex-1 justify-start" style="padding-left: 30">
      <span class="h900">{{ hours }}:{{ minutes }}</span>
    </div>

    <div class="flex-none">
      <img class="logo h-20 w-auto" :src="logoSource" />
    </div>

    <div class="flex flex-1 flex-col items-end" style="padding-right: 30">
      <div class="h700">{{ weekday }}</div>
      <div class="h600">{{ dateAndMonth }}</div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
  beforeMount() {
    clearInterval(this.intervalId);
  },
  data() {
    return {
      minutes: '00',
      hours: '00',
      weekday: 'day',
      dateAndMonth: '99 dec',
      intervalId: null,
    };
  },
  mounted() {
    // TODO: Functionality works but console shows this error everytime "Uncaught TypeError: this.$t is not a function"
    const boundSetTime = this.setTime.bind(this);
    boundSetTime();

    this.intervalId = setInterval(() => {
      boundSetTime();
    }, 60000); // 60 seconds.
  },
  computed: {
    ...mapGetters({
      logo: 'institution/logo',
      name: 'institution/name',
    }),
    onStartPage() {
      return this.$route.path === '/';
    },
    logoSource() {
      return get(this, 'logo.source', '');
    },
  },
  methods: {
    setTime() {
      const date = new Date();
      const dateNumber = date.getDate(); // 1-31
      let weekdayTranslation; // sun-mon
      let monthTranslation;
      switch (date.getDay()) {
        case 0:
          weekdayTranslation = this.$t('global.SUNDAY');
          break;
        case 1:
          weekdayTranslation = this.$t('global.MONDAY');
          break;
        case 2:
          weekdayTranslation = this.$t('global.TUESDAY');
          break;
        case 3:
          weekdayTranslation = this.$t('global.WEDNESDAY');
          break;
        case 4:
          weekdayTranslation = this.$t('global.THURSDAY');
          break;
        case 5:
          weekdayTranslation = this.$t('global.FRIDAY');
          break;
        case 6:
          weekdayTranslation = this.$t('global.SATURDAY');
          break;
        default:
          weekdayTranslation = 'Error translating date';
          break;
      }

      // month 0-11: jan-dec
      switch (date.getMonth()) {
        case 0:
          monthTranslation = this.$t('months.january');
          break;
        case 1:
          monthTranslation = this.$t('months.february');
          break;
        case 2:
          monthTranslation = this.$t('months.march');
          break;
        case 3:
          monthTranslation = this.$t('months.april');
          break;
        case 4:
          monthTranslation = this.$t('months.may');
          break;
        case 5:
          monthTranslation = this.$t('months.june');
          break;
        case 6:
          monthTranslation = this.$t('months.july');
          break;
        case 7:
          monthTranslation = this.$t('months.august');
          break;
        case 8:
          monthTranslation = this.$t('months.september');
          break;
        case 9:
          monthTranslation = this.$t('months.october');
          break;
        case 10:
          monthTranslation = this.$t('months.november');
          break;
        case 11:
          monthTranslation = this.$t('months.december');
          break;
        default:
          monthTranslation = 'Error translating month';
          break;
      }

      this.minutes = date.getMinutes().toString().padStart(2, '0'); // Add 0 in front of single digits so '5' becomes '05'
      this.hours = date.getHours().toString().padStart(2, '0');
      this.weekday = weekdayTranslation;
      this.dateAndMonth = `${dateNumber} ${monthTranslation}`; // e.g: '27 september'
    },
  },
};
</script>
<style lang="scss">
header {
  .logo {
    max-height: 120px;
  }
}
</style>
