<template>
  <div>
    <input
      :value="input"
      class="w-full input border-b-2 border-charcoal mb-16 text-6xl pb-2 font-semibold"
      :placeholder="$t(placeholder)"
    >
    <div :class="keyboardClass" />
  </div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

export default {
  name: 'KeyboardSimple',
  props: {
    keyboardClass: {
      type: String,
      'default': 'keyboard-simple'
    },
    layoutName: {
      type: String,
      'default': 'danish'
    },
    placeholder: {
      type: String,
      'default': 'global.description'
    }
  },
  data: () => ({
    input: '',
    lastKeyPress: '',
    keyboard: null,
    layout: {
      'danish': [
        '1 2 3 4 5 6 7 8 9 0 {bksp}',
        'q w e r t y u i o p å',
        'a s d f g h j k l æ ø',
        'z x c v b n m , . ? @',
        '{shift} {space}'
      ],
      'shift_danish': [
        '1 2 3 4 5 6 7 8 9 0 {bksp}',
        'Q W E R T Y U I O P Å',
        'A S D F G H J K L Æ Ø',
        'Z X C V B N M , . ? @',
        '{shift} {space}'
      ]
    },
    display: {
      '{bksp}': '<',
      '{space}': ' ',
      '{shift}': '^'
    },
    buttonTheme: [
      {
        class: 'medium',
        buttons: '{bksp}, {shift}'
      },
      {
        class: 'large',
        buttons: '{space}'
      },
      {
        class: 'shift',
        buttons: '{shift}'
      }
    ]
  }),
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    }
  },
  mounted() {
    this.keyboard = new Keyboard(this.keyboardClass, {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: this.layout,
      layoutName: this.layoutName,
      display: this.display,
      buttonTheme: this.buttonTheme
    });

    this.onKeyPress('{shift}');
  },
  methods: {
    onChange(input) {
      this.input = input;
      this.$emit('onChange', input);
      if (
        (input.length === 1 && this.lastKeyPress !== '{bksp}') ||
        (input.length === 0 && this.lastKeyPress === '{bksp}'))
      this.onKeyPress('{shift}');
    },
    onKeyPress(button) {
      this.lastKeyPress = button;
      if (button === '{shift}' || button === '{lock}') this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === this.layoutName ? `shift_${this.layoutName}` : this.layoutName;

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    }
  }
};
</script>

<style lang="scss">
  .input::placeholder {
    color: #989898;
  }
  .keyboard-simple {
    background: #fff;

    &.hg-layout-shift_danish .shift {
      background: #989898 !important;
      color: #fff;
    }

    .hg-row:nth-child(2) {
      padding-left: 20px;
      padding-right: 40px;
    }

    .hg-row:nth-child(3) {
      padding-left: 40px;
      padding-right: 20px;
    }

    .hg-row:nth-child(4) {
      padding-left: 60px;
      padding-right: 10px;
    }

    .hg-row:nth-child(5) {
      padding-left: 60px;
    }
    
    .hg-button {
      height: 70px;
      width: 70px;
      background: rgba(50, 50, 50, 0.05) !important;
      border: 1px solid #323232 !important;
      font-size: 1.8rem;
      font-weight: 700;

      &.medium {
        max-width: 140px;
      }

      &.large {
        max-width: 420px;
      }
    }
  }
</style>
