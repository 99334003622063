<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { default as ButtonEl } from '@components/shared/Button.vue';
import KeyboardSimple from '@components/shared/KeyboardSimple.vue';
import PinFlow from '@utils/pinFlow';

const store = useStore();

const suggestion = ref('');
const activeOverlay = computed(() => store.getters['general/activeOverlay']);
const data = computed(() => activeOverlay.value.data);

async function addSuggestion() {
  const pinFlow = new PinFlow({
    entity: 'meeting-suggest',
    title: '',
    sharedDepartments: data.value.sharedDepartments,
  });

  store.dispatch('meetings/setSuggestion', suggestion.value);
  pinFlow.startCreateFlow('meeting.toSuggest');
}
</script>

<template>
  <div class="px-12 relative">
    <div class="flex-2 h-full px-10 pb-12">
      <keyboard-simple
        placeholder="meeting.suggestion"
        @onChange="suggestion = $event"
      />
    </div>

    <div class="flex w-full sticky justify-center write-suggestion">
      <button-el
        class="bold"
        text="meeting.writeSuggestion"
        icon="pen-alt"
        background-color="success"
        @click="addSuggestion"
      />
    </div>
  </div>
</template>

<style lang="scss">
.write-suggestion {
  bottom: 400px;
}
</style>
